<template lang="pug">
div.files-view-container
  Loading.mt-32(v-if="loading")
  div.files-view-wrap(v-else)
    div.files-back-btn.hover-primary(@click="back")
      i.nexd-icon-32-creative-updated(aria-hidden="true")
      span.fs-14 Back to start

    template(v-if="images")
      div.files-header-txt Success!

      div.mt-32.row
        div.col.flex-grow
          div.row
            div.col-8.color-gray-800(v-for="(size, name, index) in sizes" :key="name")
              strong.fs-28(:class="{'color-success': index === 2}") {{ size | FileSize(2) }}
              div.mt-2.fs-14 {{ name }}

        div.col
          Buttons
            div.hover-primary.fs-14(@click="copyZipLink")
              i.nexd-icon-32-share(aria-hidden="true")
              span Copy ZIP link
            Button(type="primary" label="Upload more" :outline="true" :loading="uploading" @click="openFileInput")
            Button(type="primary" label="Download ZIP" :animate="true" :disabled="uploading" :href="zip_link")
          div.mt-2.text-right
            span.color-gray-800.fs-14 Zip size: {{ zip_size | FileSize(2) }}
          input.file-upload-input(:accept="file_input_accept_extensions" multiple @change="fileChangeHandler" ref="input" type="file")
      FilesList(:images="images" @updateZip="updateZip")
</template>

<script>
import { setClipboard } from '@helpers/Global';
import GlobalMixins from '@mixins/Global.vue';

import FilesList from '@components/FilesList.vue';

import Button from '@master/UI/Buttons/Button.vue';
import Buttons from '@master/UI/Buttons/Buttons.vue';
import Loading from '@master/UI/Loading.vue';

export default {
  name: 'FilesView',

  mixins: [GlobalMixins],

  components: {
    Button,
    Buttons,
    FilesList,
    Loading,
  },

  computed: {
    sizes() {
      return {
        'Original size': this.original_size,
        'Decreased size': this.decreased_size,
        'Optimized size': this.optimized_size,
      };
    },
  },

  data() {
    return {
      loading: true,
      optimization: 0,
      original_size: 0,
      decreased_size: 0,
      optimized_size: 0,
      images: null,
      zip_data: null,
      zip_link: null,
      zip_size: 0,
      uploading: false,
      files_count: 0,
      upload_error_message: null,
    };
  },

  created() {
    this.getSession();
  },

  methods: {
    back() {
      this.$router.push('/');
    },

    getSession() {
      this.$http
        .get(`tools/optimizer/${this.$route.params.session_id}`, { notification: false })
        .then(response => {
          this.original_size = response.original_total_size;
          this.optimized_size = response.optimized_total_size;
          this.decreased_size = this.original_size - this.optimized_size;

          this.optimization = 100 - Math.round((this.optimized_size / this.original_size) * 100);

          this.zip_size = response.zip_size;
          this.zip_link = response.zip_link;
          this.images = response.files;
        })
        .catch(error => {
          if (error.code === 404) {
            this.back();
          }
        })
        .finally(_ => {
          this.loading = false;
        });
    },

    updateZip(zip_size, original_total_size, optimized_total_size) {
      this.original_size = original_total_size;
      this.optimized_size = optimized_total_size;
      this.optimization = (100 - (this.optimized_size / this.original_size) * 100).toFixed(2);
      this.decreased_size = this.original_size - this.optimized_size;
      this.zip_size = zip_size;
    },

    fileChangeHandler(event) {
      this.upload
        .fileChangeHandler(event)
        .then(files => {
          this.sendFiles(files);
        })
        .catch(error => {
          this.upload_error_message = error;
        });
    },

    sendFiles(files) {
      if (this.uploading) {
        return;
      }

      this.files_count = files.length;
      this.uploading = true;

      this.$http
        .post(`tools/optimizer/${this.$route.params.session_id}`, { data: files })
        .then(_ => {
          this.clearInput();
          this.getSession();
        })
        .finally(_ => {
          this.uploading = false;
        });
    },

    copyZipLink() {
      if (this.zip_link != null) {
        setClipboard(this.zip_link)
          .then(_ => {
            this.$notifications.add('primary', 'Zip URL copied to clipboard');
          })
          .catch(error => {
            this.$notifications.add('warning', error);
          });
      }
    },
  },
};
</script>
