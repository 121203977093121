import FilterService from '@master/Services/FilterService';
import Subscriber from '@libs/Subscriber';

class KeywordsService extends Subscriber {
  subscribe(cb, vnode = null, creatives = false) {
    if (this.data === undefined) {
      this.load(creatives);
    }
    super.subscribe(cb, vnode);
  }

  load(creatives = false) {
    let path = 'v2/campaigns/keywords';
    if (creatives) {
      path = 'creatives/keywords';
    }
    return super.load(path);
  }

  update(kw, add = true) {
    if (this.data === undefined) return;

    if (add) {
      this.#addKeyword(kw);
    } else {
      this.#removeKeyword(kw);
    }
  }

  #addKeyword(kw) {
    for (const keyword of this.data) {
      if (kw === keyword.value) {
        keyword.count++;
        FilterService.addOption('campaigns.keywords', kw);
        return;
      }
    }
    this.data.push({ value: kw, count: 1 });
    FilterService.addOption('campaigns.keywords', kw);
  }

  #removeKeyword(kw) {
    for (const index in this.data) {
      const keyword = this.data[index];

      if (kw === keyword.value) {
        if (keyword.count > 1) {
          keyword.count--;
          break;
        }
        this.data.splice(index, 1);
        break;
      }
    }
    FilterService.removeOption('campaigns.keywords', kw);
  }
}

export default new KeywordsService();
