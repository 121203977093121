import Subscriber from '@libs/Subscriber';

class FilterService extends Subscriber {
  subscribe(cb, vnode = null) {
    super.subscribe(cb, vnode);
  }

  load() {
    return super.load(this.#getPath());
  }

  removeOption(filter, option) {
    if (this.data?.[filter] == null) return;

    let options = this.data;
    const previous_filter_data = options[filter].find(
      filter => filter.value === option,
    );

    if (previous_filter_data?.count > 1) {
      previous_filter_data.count--;
    } else {
      options[filter].splice(options[filter].indexOf(previous_filter_data), 1);
    }
    this.#updateOptions(filter, options[filter]);
  }

  addOption(filter, option) {
    if (this.data?.[filter] == null) return;

    let options = this.data;
    const previous_filter_data = options[filter].find(
      filter => filter.value === option,
    );

    if (previous_filter_data) {
      previous_filter_data.count++;
    } else {
      options[filter].push(this.generateOption(option, filter));
    }

    this.#updateOptions(filter, options[filter]);
  }

  getOption(value, filter) {
    if (this.data?.[filter] == null) return;

    return this.data[filter].find(option => {
      if (option.value != null) {
        /**
         * @description Some options can have multiple values separated by commas, e.g. 3D Cube in layout filter
         */
        return option.value.toString().split(',').includes(value);
      }
    });
  }

  #getPath() {
    const active_user_id = this.#getId('active_item_id');

    if (active_user_id != null) {
      return `v2/user/${active_user_id}/campaigns/filters`;
    }

    return 'v2/campaigns/filters';
  }

  #getId(key) {
    return this.router?.history?.current?.params?.[key] ?? null;
  }

  #updateOptions(filter, updated_options) {
    if (this.data?.[filter] == null) return;

    let options = this.data;

    options[filter] = this.#sortOptions(updated_options).reduce(
      (result, option) => {
        result.push({
          value: option.value,
          label: option.value,
          count: option.count,
          filter,
        });
        return result;
      },
      [],
    );

    this.data = options;
  }

  #sortOptions(options) {
    if (options == null || options.constructor !== Array) return [];

    return [...options].sort((a, b) => {
      if (a?.value?.toLowerCase() < b?.value?.toLowerCase()) return -1;
      if (a?.value?.toLowerCase() > b?.value?.toLowerCase()) return 1;
      return 0;
    });
  }

  /**
   * @description Can be used for keyword and advertiser since their value === label
   */
  generateOption(value, filter, count = 1) {
    return {
      value,
      label: value,
      count,
      filter,
    };
  }
}

export default new FilterService();
