class SortableTable {
  constructor(table, data) {
    this.table = table;
    this.headerNodes = [];
    this.activeHeaderNode = null;
    this.data = data;
    this.initHeaders();
  }
  initHeaders() {
    // add carets to the head elements
    let headerColumns = this.table.querySelectorAll('thead th');
    let initialOrder = null;

    for (const header of headerColumns) {
      // add click event to the single Node
      const key = header.getAttribute('data-key');
      if (!key) continue;

      // create new header content, with carets
      let headerNode = document.createElement('div');
      headerNode.className = 'sortable-header';
      let caret = document.createElement('i');
      caret.className = 'nexd-icon-32-up-down';

      const content = header.innerHTML;
      let span = document.createElement('span');
      span.innerHTML = content;

      // save caret info into the Node
      headerNode._caret = caret;
      headerNode.appendChild(span);
      headerNode.appendChild(caret);

      // remove old header content
      header.innerHTML = '';
      // add new header content
      header.appendChild(headerNode);
      this.headerNodes.push(headerNode);

      const defaultOrder = header.getAttribute('default-order');
      if (defaultOrder != null) {
        initialOrder = {
          headerNode,
          key,
          direction: true,
        };
        if (defaultOrder === 'desc') {
          initialOrder.direction = false;
        }
      }
      this.addEvent(headerNode, key);
    }

    if (initialOrder !== null) {
      this.activeHeaderNode = initialOrder.headerNode;
      this.initColumnSorting(initialOrder.headerNode, initialOrder.direction);
      this.orderBy(initialOrder.key, initialOrder.direction);
    }
  }
  initColumnSorting(node, direction = true) {
    // node._caret.classList.add('nexd-icon-32-arrow-down-small');
    // node._caret.classList.remove('nexd-icon-32-up-down');
    if (direction === true) {
      node._caret.parentNode.parentNode.classList.add('asc');
    } else {
      node._caret.parentNode.parentNode.classList.add('desc');
    }
    node._direction = direction;
  }
  removeColumnSorting(node) {
    // node._caret.classList.add('nexd-icon-32-up-down');
    // node._caret.classList.remove('nexd-icon-32-arrow-down-small');
    node._caret.parentNode.parentNode.classList.remove('asc');
    node._caret.parentNode.parentNode.classList.remove('desc');
    node._direction = null;
  }
  addEvent(headerNode, key) {
    headerNode.addEventListener('click', () => {
      if (headerNode !== this.activeHeaderNode) {
        if (this.activeHeaderNode !== null) {
          // remove active header Node classes
          this.removeColumnSorting(this.activeHeaderNode);
        }
        // add classes to new active Node
        this.initColumnSorting(headerNode);
        // save reference for active Node
        this.activeHeaderNode = headerNode;
      } else {
        // click on the same header
        headerNode._direction = !headerNode._direction;
        if (headerNode._direction === true) {
          headerNode._caret.parentNode.parentNode.classList.add('asc');
          headerNode._caret.parentNode.parentNode.classList.remove('desc');
        } else {
          headerNode._caret.parentNode.parentNode.classList.add('desc');
          headerNode._caret.parentNode.parentNode.classList.remove('asc');
        }
      }
      this.orderBy(key, headerNode._direction);
    });
  }
  orderBy(key, direction) {
    if (this.data == null) {
      return;
    }
    this.data.sort((a, b) => {
      let valA = a;
      let valB = b;
      const keys = key.split('.');
      for (const k of keys) {
        valA = valA[k];
        valB = valB[k];
      }

      if (isNaN(valA) && isNaN(valB)) {
        // reverse string sorting, as from A to Z
        if (valA > valB) {
          return direction ? 1 : -1;
        } else if (valA < valB) {
          return direction ? -1 : 1;
        }
        return 0;
      }

      if (valA < valB) {
        return direction ? 1 : -1;
      } else if (valA > valB) {
        return direction ? -1 : 1;
      }
      return 0;
    });
  }
  updateData(data) {
    this.data = data;
  }
}

export default SortableTable;
