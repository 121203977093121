import ModulesService from '@master/Services/Cache/ModulesService';
import UIGroupsService from '@master/Services/Cache/UIGroupsService';
import { clone } from '@helpers/Global';

const getUiGroupBase = () => {
  return {
    ui_group_key: null,
    title: null,
    description: null,
    tooltip: null,
    slots: [],
    modules: [],
    groups: {},
    settings: null,
  };
};

let ui_groups = {};

const resetUIGroup = () => {
  ui_groups = {};
};

const pushToUIGroup = (obj, type) => {
  let uikey = 'no_group';
  if (obj.ui_group_key != null) {
    uikey = obj.ui_group_key;
  } else {
    return; // all parent assets should have atleast one slot
  }
  if (ui_groups[uikey] == null) {
    const newgroup = UIGroupsService.getUIGroup(uikey);
    if (newgroup == null) {
      ui_groups[uikey] = new getUiGroupBase();
    } else {
      newgroup.modules = [];
      newgroup.slots = [];
      ui_groups[uikey] = newgroup;
    }
  }
  ui_groups[uikey][type].push(obj);
};

class LayoutGrouper {
  constructor(layout) {
    let _layout = clone(layout) || null;
    if (_layout == null || _layout.layout_id == null) {
      throw new Error('Layout not defined');
    }
    resetUIGroup();
    this.ui_groups = {};
    this.object_tree = [];
    this._object_library = {};

    let tree = '';

    if (_layout.slots != null) {
      for (const k in _layout.slots) {
        _layout.slots[k] = this.Slot(_layout.slots[k], tree);
        pushToUIGroup(_layout.slots[k], 'slots');
      }
    }

    if (_layout.modules != null) {
      for (const k in _layout.modules) {
        _layout.modules[k] = this.Module(_layout.modules[k], tree);
        pushToUIGroup(_layout.modules[k], 'modules');
      }
    }
    this.object_tree.push(tree);
    this.object_tree.reverse();

    // TODO: Think something about removing duplicates
    // this.removeDuplicatesFromGroups(ui_groups);

    this.ui_groups = ui_groups;

    this.layout = _layout;
  }

  removeDuplicatesFromGroups(groups) {
    if (groups.slots != null) {
      for (const k in groups.slots) {
        if (groups.slots[k].ui_group_key != null) {
          delete groups.slots[k];
        }
      }
    }
    if (groups.modules != null) {
      for (const k in groups.modules) {
        if (groups.modules[k].ui_group_key != null) {
          delete groups.modules[k];
        }
      }
    }
  }

  getUiGroups() {
    return this.ui_groups;
  }

  getItemFromLibrary(id) {
    return this._object_library[id] || null;
  }

  getBaseSlotObjectId() {
    // First let's find layout base slot module
    if (this.layout.slots != null) {
      for (const slot_id in this.layout.slots) {
        // Currently taking only first slot - since survey is first case
        if (this.layout.slots[slot_id].modules != null && this.layout.slots[slot_id].modules.length > 0 && this.layout.slots[slot_id].modules[0].module_id != null) {
          return `${slot_id}.${this.layout.slots[slot_id].modules[0].module_id}`;
        }
      }
    }
    return null;
  }

  Slot(slot, tree) {
    let _slot = clone(slot);
    if (_slot == null) {
      throw new Error('Slot failed');
    }

    tree += `.${_slot.slot_id}`;
    if (_slot.modules != null) {
      for (const k in _slot.modules) {
        _slot.modules[k] = this.Module(_slot.modules[k], tree);
      }
    }
    tree = tree
      .split('.')
      .filter(item => item != null && item !== '')
      .join('.');
    this.object_tree.push(tree);
    _slot._fullpath = tree;

    pushToUIGroup(_slot, 'slots');

    if (tree.length > 0) {
      this._object_library[tree] = clone(slot);
    }

    return _slot;
  }

  Module(key, tree) {
    if (key == null) {
      throw new Error("Couldn't load module. Key is missing");
    }

    let _module = ModulesService.getModule(key);

    tree += `.${_module.module_id}`;

    if (_module.slots != null) {
      for (const k in _module.slots) {
        _module.slots[k] = this.Slot(_module.slots[k], tree);
      }
    }
    tree = tree
      .split('.')
      .filter(item => item != null && item !== '')
      .join('.');
    this.object_tree.push(tree);
    _module._fullpath = tree;

    if (tree.length > 0) {
      this._object_library[tree] = ModulesService.getModule(key);
    }

    return _module;
  }
}

const LayoutHelper = {
  _cache: {},

  load(layout) {
    if (layout == null || layout.layout_id == null) {
      throw new Error('Layout not provided for LayoutService');
    }
    if (this._cache[layout.layout_id] == null) {
      const layout_grouper = new LayoutGrouper(layout);
      this._setToCache(layout.layout_id, layout_grouper);
    }
  },

  getUiGroups(id) {
    return this._getFromCache(id).getUiGroups();
  },

  getLayoutItem(layout_id, id) {
    if (layout_id == null || id == null) {
      throw new Error('Layout ID and slot/module ID has to be provided');
    }
    return this._getFromCache(layout_id).getItemFromLibrary(id);
  },

  getLayoutItemRootSlot(layout_id, id) {
    if (layout_id == null || id == null) {
      throw new Error('Layout ID and slot/module ID has to be provided');
    }
    const idlist = id.split('.').slice(0, 3);
    if (idlist.length < 3) {
      return null;
    }
    id = idlist.join('.');
    return this._getFromCache(layout_id).getItemFromLibrary(id);
  },

  getLayoutBaseSlotModuleObjectId(layout_id) {
    if (layout_id == null) {
      throw new Error('Layout ID has to be provided');
    }
    return this._getFromCache(layout_id).getBaseSlotObjectId();
  },

  _setToCache(layout_id, layout) {
    this._cache[layout_id] = layout;
  },

  _getFromCache(layout_id) {
    if (this._cache[layout_id] == null) {
      throw new Error('Could not find layout from cache');
    }
    return this._cache[layout_id];
  },
};

export default LayoutHelper;
