class NotificationService {
  #notifications = [];

  get() {
    return this.#notifications;
  }

  add(type, msg, close = 6000) {
    let new_notification = {
      type,
      msg,
      class: 'visible',
    };

    new_notification.timeout = setTimeout(() => {
      this.close(new_notification);
    }, close);

    this.#notifications.unshift(new_notification);
  }

  close(notification = null) {
    if (notification == null) return;

    clearTimeout(notification.timeout);

    notification.class = 'hide';

    notification.timeout = setTimeout(() => {
      this.#notifications.shift(this.#notifications.indexOf(notification), 1);
    }, 300);
  }
}

export default new NotificationService();
