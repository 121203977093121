import UserService from '@master/Services/UserService';
import $http from '@master/Services/HttpService';

class Payment {
  constructor() {
    // iframe element where things will be done
    this.parent = null;
    this.iframe = null;
    // callback when iframe fires message
    this.messageCallback = null;
  }

  addCard() {
    const params = new URLSearchParams({
      ref: window.location.href,
    });

    if (process.env.NODE_ENV === 'development') {
      params.append('env', process.env.NODE_ENV);
    }

    const redirect = `${window.__nexd.api}v2/payments/card/add?${params.toString()}`;
    window.location.href = redirect;
  }

  removeCard() {
    if (!UserService.groupHasActiveCreditCard()) return;

    const path = `v2/payments/card`;
    return $http
      .delete(path)
      .catch(_ => {
        /** supress errors, notifications handled by the service */
      })
      .finally(_ => {
        const group = UserService.getGroup();
        // something changed while request active aka log out
        if (!group) return;
        group.meta.has_active_cc = false;
      });
  }
}

// just export the class, and force components creating a new instance, so constructor would be fired with none, timestamp etc
export default Payment;
