<template lang="pug">
div#app(v-if="$route.meta.loaded")
  Navbar(ref="navbar" :app-name="$route.meta.app_name")
  Notifications
  div#content
    router-view
</template>

<script>
import Navbar from '@master/UI/Navbar/Navbar.vue';
import Notifications from '@master/UI/Notifications.vue';

export default {
  name: 'app',

  components: {
    Navbar,
    Notifications,
  },
};
</script>
