import { b64toBlob } from '@helpers/Global';
import Layer from './Layer';

export default class BackgroundLayer extends Layer {
  constructor(name, width, height, zIndex) {
    super(width, height);

    this.name = name;
    this.zIndex = zIndex;

    this.canvas = this.#createCanvas();
    this.ctx = this.canvas.getContext('2d');

    this.img = null;
  }

  async drawAssets() {
    for (const asset of this.assets) {
      // make sure to draw assets in order
      await asset.draw(this);
    }
  }

  getImage() {
    // only create image once
    // as when debugging this might be called multiple times
    if (this.img) {
      return this.img;
    }
    this.img = window.URL.createObjectURL(b64toBlob(this.canvas.toDataURL('image/png')));
    return this.img;
  }

  #createCanvas() {
    const canvas = document.createElement('canvas');
    canvas.width = this.width;
    canvas.height = this.height;
    const ctx = canvas.getContext('2d');
    ctx.translate(canvas.width * 0.5, canvas.height * 0.5);
    return canvas;
  }
}
