export default (bytes, round = 0) => {
  if (bytes == null || bytes === 0) {
    return '0 B';
  }

  let is_negative = false;
  if (bytes < 0) {
    is_negative = true;
    bytes *= -1;
  }

  const k = 1024;
  const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  let rounding = 1;
  if (round > 0) {
    rounding = 10 * round;
  }
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  let value = Math.round((bytes / Math.pow(k, i)) * rounding) / rounding;

  if (is_negative) {
    value *= -1;
  }

  return `${value} ${sizes[i]}`;
};
