import moment from 'moment';

import DateFilter from './DateFilter';

export default (value, value2, options = null) => {
  if (value == null || value2 == null) {
    return '-';
  }

  options = options ?? {};
  options.utc = options.utc ?? false;
  options.time = options.time ?? true;
  options.format = options.format ?? null;

  let secondary_options = { ...options };

  [value, value2] = [Math.min(value, value2), Math.max(value, value2)];

  let datefn = moment;
  if (options?.utc) {
    datefn = datefn.utc;
  }

  if (datefn(value * 1000).isSame(value2 * 1000, 'day')) {
    if (!options.time && !options.format) {
      return DateFilter(value, options);
    }

    // if the given format does not contain time and the date is same, dont return range
    if (options.format && !options.format.includes('mm') && !options.format.includes('HH')) {
      return DateFilter(value, options);
    }

    secondary_options.time = false;
    secondary_options.format = 'HH:mm';
  }

  return `${DateFilter(value, options)} - ${DateFilter(value2, secondary_options)}`;
};
