function filterEmail(value) {
  if (!value.includes('@')) return this.basicFilter(value);

  const [username, domain] = value.split('@');
  const first_name_char = username[0];
  const hidden_name =
    first_name_char + username.substring(1).replace(/./g, '*');
  const first_domain_char = domain[0];
  const hidden_domain =
    first_domain_char + domain.substring(1).replace(/./g, '*');
  return `${hidden_name}@${hidden_domain}`;
}

function basicFilter(value) {
  // hide 20%
  const amount = Math.ceil(value.length / 5);
  return (
    value.substring(0, amount) + value.substring(amount).replace(/./g, '*')
  );
}

export default (value, type = null, revealed = false) => {
  if (value == null) return '-';
  if (revealed) return value;
  if (type === 'email') {
    return filterEmail(value);
  }
  return basicFilter(value);
};
