import { IMPRESSION_TYPES } from '@master/constants';

export default value => {
  switch (value) {
    case IMPRESSION_TYPES.IMPRESSIONS:
      return 'Tracked ads';
    case IMPRESSION_TYPES.VIEWABLE:
      return 'Viewable';
    case IMPRESSION_TYPES.LOADED:
      return 'Loaded';
    case IMPRESSION_TYPES.BILLABLE:
      return 'Billable';
    default:
      return 'Not set';
  }
};
