import { clone } from '@helpers/Global';
import Layer from './Layer';
import Drawable from './Drawable';

export default class Slot {
  #settings;
  #cropped_width;
  #cropped_height;

  constructor(asset_id, uploaded_asset = null) {
    this.asset_id = asset_id;
    if (uploaded_asset) {
      // customly added assets dont have uploaded asset info
      // eg when adding defautl video controls or arrows etc
      if (uploaded_asset.user_scale) {
        // output scale is the size in cropper vs actually cropped
        this.#cropped_width = uploaded_asset.width / uploaded_asset.output_scale;
        this.#cropped_height = uploaded_asset.height / uploaded_asset.output_scale;
      }
    }
    this.image_uri = uploaded_asset?.image_uri ?? null;
    this.blob = uploaded_asset?.blob ?? null;
    this.user_scale = uploaded_asset?.user_scale ?? 1;
    this.#settings = clone(uploaded_asset?.settings ?? {});
    this.#validateSettings();
  }

  canDraw() {
    return this.image_uri != null || this.blob != null;
  }

  getX() {
    return this.#settings.SourceLayer.position.x;
  }

  getY() {
    return this.#settings.SourceLayer.position.y;
  }

  getWidth() {
    return this.#cropped_width ?? this.#settings.CropLayer.size.width;
  }

  getHeight() {
    return this.#cropped_height ?? this.#settings.CropLayer.size.height;
  }

  adjustForFeed(template_asset) {
    // main assets actually dont move, so use template position always
    const settings = this.#settings;
    settings.SourceLayer.position.x = template_asset.x;
    settings.SourceLayer.position.y = template_asset.y;
  }

  /**
   * @param {Layer} layer
   */
  adjustForOverlay(layer) {
    const settings = this.#settings;
    // overlays have opposite layer sizing logic
    settings.CropLayer.size = settings.SourceLayer.size;
    // overlays dont have position, but offset instead, valulate position by offset
    settings.SourceLayer.position = this.getOffsetPosition(layer.width, layer.height);
  }

  adjustForFallback(asset, sizes) {
    // fallback gray asset, built from tempalte asset and rec. settings
    this.#settings = {
      CropLayer: {
        size: sizes.dimensions,
      },
      SourceLayer: {
        position: {
          x: asset.x,
          y: asset.y,
        },
      },
    };
  }

  /**
   * @param {Drawable} drawable
   */
  adjustForParent(drawable) {
    const settings = this.#settings;
    // move to correct position related to parent
    settings.SourceLayer.position.x += drawable.x;
    settings.SourceLayer.position.y += drawable.y;
  }

  customSettings({ x, y, width, height }) {
    const settings = this.#settings;
    settings.SourceLayer.position.x = x;
    settings.SourceLayer.position.y = y;
    settings.CropLayer.size.width = width;
    settings.CropLayer.size.height = height;
  }

  getOffsetPosition(width, height) {
    let x = 0;
    let y = 0;

    const size = {
      width: this.getWidth(),
      height: this.getHeight(),
    };

    // offset might not exist when asset is still being cropped
    // set as 0,0
    const offset = this.#settings.SourceLayer.offset ?? {};
    const offset_x = Math.min(1, Math.max(-1, offset?.x ?? 0));
    const offset_y = Math.min(1, Math.max(-1, offset?.y ?? 0));

    if (offset_x !== 0) {
      const half_width = width * 0.5;
      x = half_width * offset_x;
      if (offset_x > 0) {
        x -= size.width * 0.5;
      } else if (offset_x < 0) {
        x += size.width * 0.5;
      }
    }
    if (offset_y !== 0) {
      const half_height = height * 0.5;
      y = half_height * offset_y;
      if (offset_y > 0) {
        y -= size.height * 0.5;
      } else if (offset_y < 0) {
        y += size.height * 0.5;
      }
    }

    return { x, y };
  }

  // for sanity, make sure settings that are used exists
  #validateSettings() {
    if (!this.#settings.CropLayer) {
      this.#settings.CropLayer = {};
    }
    if (!this.#settings.SourceLayer) {
      this.#settings.SourceLayer = {};
    }

    if (!this.#settings.CropLayer.size) {
      this.#settings.CropLayer.size = { x: 0, y: 0 };
    }
    if (!this.#settings.SourceLayer.position) {
      this.#settings.SourceLayer.position = { x: 0, y: 0 };
    }
  }
}
