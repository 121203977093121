import AssetHelper from '@helpers/Asset';
import { clone } from '@helpers/Global';

import Slot from './Slot';
import Background from './Background';
import MainHandler from './MainHandler';

export default class TemplateHandler extends MainHandler {
  #template;
  #template_assets;
  #arno;

  /**
   * @param {Background} background
   */
  constructor(background) {
    super(background);
    this.#template = this.getCreative().template ?? {};
    this.#template_assets = this.#template.assets ?? {};
  }

  handleAssets() {
    const assets = this.#getAssets();

    // handle template assets and overlays
    for (const asset_id in this.#template_assets) {
      if (AssetHelper.isHiddenInCropper(asset_id)) {
        continue;
      }

      // clone so we could modify it on the go with arno
      const template_asset = clone(this.#template_assets[asset_id]);

      const is_background = AssetHelper.isBackground(template_asset);
      const is_overlay = AssetHelper.isOverlay(template_asset);

      const uploaded_asset = assets[asset_id] ?? null;
      const has_uploaded_asset = uploaded_asset != null;

      const slot = new Slot(asset_id, uploaded_asset);

      // special case is carousel splitscreen top media
      if (!is_overlay && !is_background && !template_asset.filename.startsWith('top.')) {
        this.setFirstFeedAsset(template_asset);
      }

      if (has_uploaded_asset) {
        this.#handleUploadedAsset(slot, template_asset);
      } else if (!is_background) {
        this.#handleEmptyAsset(slot, template_asset);
      }
    }
  }

  setArno(arno) {
    this.#arno = arno;
  }

  #getAssets() {
    const creative = this.getCreative();
    return creative.assets ?? {};
  }

  /**
   *
   * @param {Slot} slot
   * @param {object} template_asset
   */
  #handleUploadedAsset(slot, template_asset) {
    const is_background = AssetHelper.isBackground(template_asset);
    const is_overlay = AssetHelper.isOverlay(template_asset);

    if (is_background) {
      slot.adjustForFeed(template_asset);
      this.slots.Background.push(slot);
    } else if (is_overlay) {
      slot.adjustForOverlay(this.getBackground().layers.Overlay);
      this.slots.Overlay.push(slot);
    } else {
      if (this.#arno != null) {
        const arno_asset = AssetHelper.getTemplateAssetForArno(template_asset);
        const sizes = AssetHelper.getSizeFromArno(this.#arno, arno_asset, this.getCreative());
        template_asset.x = sizes.x;
        template_asset.y = sizes.y;
      }

      slot.adjustForFeed(template_asset);
      this.slots.Assets.push(slot);
    }
  }

  /**
   * @param {Slot} slot
   * @param {object} template_asset
   */
  #handleEmptyAsset(slot, template_asset) {
    // no gray boxes for optionals or for floating obj
    if (template_asset.required === 0 || AssetHelper.isFloatingObject(template_asset)) {
      return;
    }

    if (this.#arno != null) {
      const arno_asset = AssetHelper.getTemplateAssetForArno(template_asset);
      const sizes = AssetHelper.getSizeFromArno(this.#arno, arno_asset, this.getCreative());
      template_asset.x = sizes.x;
      template_asset.y = sizes.y;
      slot.adjustForFallback(template_asset, sizes);
    } else {
      // only feed assets can be frawn as fallback gray boxes
      const sizes = AssetHelper.getSize(this.getCreative(), template_asset.asset_id);
      // mock asset to create gray box
      slot.adjustForFallback(template_asset, sizes);
    }

    const is_overlay = AssetHelper.isOverlay(template_asset);
    if (is_overlay) {
      this.slots.Overlay.push(slot);
    } else {
      this.slots.Assets.push(slot);
    }
  }
}
