import RequestCache from '@libs/RequestCache';
import Service from '@libs/Service';

import { clone } from '@helpers/Global';

class SubscriberData {
  constructor(subscriber, data) {
    this.subscriber = subscriber;
    this.data = data;
  }
  get() {
    return this.data;
  }
  find(find) {
    return this.data.find(find);
  }
  isEmpty() {
    return this.data.length === 0;
  }
  push(item) {
    this.data.push(item);
    this.subscriber.sendAll();
  }
  splice(index = 0, count = 1) {
    this.data.splice(index, count);
    this.subscriber.sendAll();
  }
  sort(compare) {
    this.data.sort(compare);
    this.subscriber.sendAll();
  }
}

class Subscriber extends Service {
  #resolvers = new Set();

  // by default, theres no data unless set
  #data;

  get data() {
    // if called before setting, return undefined
    if (this.#data === undefined) return undefined;
    return this.#data.get();
  }

  set data(data) {
    // create data object when setting data
    this.#data = new SubscriberData(this, data);
    this.sendAll();
  }

  load(path, options, cache = false) {
    if (this.loading) return this.loading;

    const request = cache ? RequestCache : this.$http;

    this.loading = request
      .get(path, { notification: options?.notification ?? true })
      .then(response => {
        if (options?.keys != null && options?.keys?.length > 0) {
          this.data = {
            ...this.data,
            ...response,
            ...options?.keys?.reduce((acc, key) => {
              acc[key] = [...(this.data?.[key] ?? []), ...response[key]];
              return acc;
            }, {}),
          };
        } else {
          this.data = response;
        }

        return this.data;
      })
      .finally(_ => {
        this.loading = null;
      });

    return this.loading;
  }

  subscribe(cb, vnode = null) {
    if (vnode == null && process.env.NODE_ENV === 'development') {
      console.warn('Subscriber added without vnode reference');
    }

    if (vnode != null && typeof vnode.$once === 'function') {
      vnode.$once('hook:beforeDestroy', _ => {
        this.#resolvers.delete(cb);
      });
    }

    this.#resolvers.add(cb);
    this.#send(cb);
  }

  #send(fn) {
    if (this.data === undefined) return;
    fn(clone(this.data));
  }

  sendAll() {
    if (this.data === undefined) return;

    for (const cb of this.#resolvers) {
      this.#send(cb);
    }
  }

  updateCache(key) {
    if (this.data) {
      RequestCache.update(key, this.data);
    }
  }
}

export default Subscriber;
