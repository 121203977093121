import { TIMEOUT } from '@master/constants';
import { addFiltersToPath } from '@helpers/Global';

import QueryOptions from '@libs/QueryOptions';
import RequestLimiter from '@libs/RequestLimiter';
import Subscriber from '@libs/Subscriber';

import $http from '@master/Services/HttpService';
import UserService from '@master/Services/UserService';

const STATUS_MARKED_SEEN = 2;
const POPUP_LIMITER_KEY = 'popup-seen';

class UserNotificationService extends Subscriber {
  #path = 'auth/notifications';
  #options = new QueryOptions();
  #popup_seen_ids = [];
  #notifications_interval = null;

  constructor() {
    super();
    this.#reset();
  }

  start() {
    // ignore double starts
    if (!this.#notifications_interval) {
      // poll instantly
      this.poll();

      // poll in interval
      this.#notifications_interval = setInterval(_ => this.poll(), TIMEOUT.FIVE_MINUTES);
    }
  }

  stop() {
    window.clearInterval(this.#notifications_interval);
    this.#notifications_interval = false;
  }

  load() {
    this.#options.reset();
    this.#reset();
    this.#load();
  }

  loadMore() {
    this.#options.update();
    this.#load();
  }

  poll() {
    if (!UserService.isActive()) {
      return UserService.triggerWhenActive('notification-poll', () => this.poll());
    }

    $http
      .get('auth/notifications/poll')
      .then(({ global, unseen, count }) => {
        this.data = {
          ...this.data,
          count,
          global,
          unseen,
        };
      })
      .catch(_ => {
        /** supress errors */
      });
  }

  seen(notification, preview = false) {
    const notifications = this.data.notifications;

    for (const group in notifications) {
      for (const n of notifications[group]) {
        if (notification.notification_id === n.notification_id) {
          n.status = STATUS_MARKED_SEEN;
        }
      }
    }

    this.#change('notifications', notifications);

    if (preview) {
      this.#change(
        'unseen',
        this.data.unseen.filter(n => n.notification_id !== notification.notification_id),
      );
    }

    $http
      .put(`auth/notifications/${notification.notification_id}/seen`)
      .then(({ count }) => {
        this.#change('count', count);
      })
      .catch(_ => {
        /** supress errors */
      });
  }

  seenAll() {
    const notifications = {};

    for (const group in this.data.notifications) {
      notifications[group] = [];
      for (const notification of this.data.notifications[group]) {
        notification.status = STATUS_MARKED_SEEN;
        notifications[group].push(notification);
      }
    }

    this.data = {
      ...this.data,
      notifications,
      count: {
        unseen: 0,
        total: this.data.count.total,
      },
    };

    $http.put('auth/notifications/bulk/seen').catch(_ => {
      /** supress errors */
    });
  }

  popupSeen(notification) {
    this.#popup_seen_ids.push(notification.notification_id);

    RequestLimiter.hook(
      POPUP_LIMITER_KEY,
      () => {
        this.#change(
          'unseen',
          this.data.unseen.filter(n => !this.#popup_seen_ids.includes(n.notification_id)),
        );
        $http.put(`auth/notifications/popup`, { notifications: this.#popup_seen_ids }).catch(_ => {
          /** supress errors */
        });
        this.#popup_seen_ids = [];
      },
      10000,
    );
  }

  popupSeenAll() {
    RequestLimiter.unhook(POPUP_LIMITER_KEY);
    this.#change('unseen', null);
    $http.put(`auth/notifications/bulk/popup`).catch(_ => {
      /** supress errors */
    });
  }

  remove(notification) {
    const notifications = this.data.notifications;

    for (const group in notifications) {
      notifications[group] = notifications[group].filter(n => n.notification_id !== notification.notification_id);
    }

    this.#change('notifications', notifications);

    $http
      .delete(`auth/notifications/${notification.notification_id}/remove`)
      .then(({ count }) => {
        this.#change('count', count);
      })
      .catch(_ => {
        /** supress errors */
      });
  }

  removeAll() {
    this.#reset();
    $http.delete('auth/notifications/bulk/remove').catch(_ => {
      /** supress errors */
    });
  }

  #load() {
    $http
      .get(addFiltersToPath(this.#path, this.#options.get()))
      .then(response => {
        if (this.data?.notifications) {
          this.data = {
            count: response.count,
            notifications: this.#mergeNotifications(this.data.notifications, response.notifications),
          };
        } else {
          this.data = response;
        }
      })
      .catch(_ => {
        this.data = {
          not_found: true,
        };
      });
  }

  #change(key, value) {
    if (this.data == null) {
      this.#reset();
    }

    this.data = {
      ...this.data,
      [key]: value,
    };
  }

  #reset() {
    this.data = {
      notifications: null,
      count: {
        unseen: 0,
        total: 0,
      },
      global: null,
      unseen: null,
    };
  }

  #mergeNotifications(current, added) {
    for (const group in current) {
      if (added[group]?.length > 0) {
        current[group] = [...current[group], ...added[group]];
      }
    }

    return current;
  }
}

export default new UserNotificationService();
