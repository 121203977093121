/**
 * @example v-google-analytics="{ click: { event: 'customEvent', element_name: 'create new folder button' } }"
 */
export default {
  inserted: async (el, binding) => {
    if (binding?.value == null) return;

    const element_type =
      el?.tagName?.toLowerCase() ?? el?.nodeName?.toLowerCase() ?? undefined;
    const data = binding?.value;

    if (data?.key == null) {
      if (process.env.VUE_APP_ENV !== 'prd') {
        console.error('v-google-analytics: key is required, data: ', data);
      }
      return;
    }

    switch (data.key) {
      case 'change':
        return (el.onchange = () =>
          window?.ga?.e({ ...data, element_type, interaction_type: 'change' }));
      case 'click':
        return (el.onclick = () => {
          window?.ga?.e({ ...data, element_type, interaction_type: 'click' });
          return true;
        });
      case 'mousedown':
        return (el.onmousedown = () =>
          window?.ga?.e({
            ...data,
            element_type,
            interaction_type: 'mousedown',
          }));
      case 'mouseup':
        return (el.onmouseup = () =>
          window?.ga?.e({
            ...data,
            element_type,
            interaction_type: 'mouseup',
          }));
      case 'focus':
        return (el.onfocus = () =>
          window?.ga?.e({ ...data, element_type, interaction_type: 'focus' }));
      case 'select':
        return (el.onselect = () =>
          window?.ga?.e({ ...data, element_type, interaction_type: 'select' }));
      case 'submit':
        return (el.onsubmit = () =>
          window?.ga?.e({ ...data, element_type, interaction_type: 'submit' }));
      default:
        return;
    }
  },
};
