import Button from '@master/UI/Buttons/Button.vue';
import Buttons from '@master/UI/Buttons/Buttons.vue';

export const types = [
  'primary',
  'secondary',
  'success',
  'warning',
  'danger',
  'update',
  'media-buy',
  'link',
  'link-primary',
  'link-accent',
  'link-danger',
  'link-light',
  'dropdown',
  'quantum',
  'cyan',
  'light',
  'navbar',
  'banner',
  'filter',

  // login specific buttons
  'login-primary',
  'login-verify',
  'login-secondary',
  'login-google',
  'login-microsoft',
];

export function createButton(Vue, container, resolve, propsData) {
  const action = propsData.action;
  delete propsData.action;

  const ButtonComponent = Vue.extend(Button);
  const button = new ButtonComponent({ propsData });
  button.$mount();

  button.$on('click', e => {
    e.preventDefault();
    e.stopPropagation();
    resolve(action);
    container?.close?.();
  });

  return button.$el;
}

export function createButtons(Vue, parent) {
  const ButtonsComponent = Vue.extend(Buttons);
  const buttons = new ButtonsComponent({ propsData: { align: 'right' } });
  buttons.$mount();
  parent.appendChild(buttons.$el);

  return buttons.$el;
}
