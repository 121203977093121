<template lang="pug">
div.adfooter-container
  div.adfooter-wrap
    div.adfooter-header-small.text-center
      | Want to build ads as light and fast as the assets you just optimized?
      br
      | Try out
    div.adfooter-header-big.text-center Campaign Manager
    div.adfooter-img-container
      div.left-image
        img(:src="$cdn + 'dist/assets/optimizer/laptop.svg'" style="height: 260px" alt="laptop")
      div.front-image
        img(:src="$cdn + 'dist/assets/optimizer/laptop.svg'" alt="laptop")
      div.right-image
        img(:src="$cdn + 'dist/assets/optimizer/laptop.svg'" style="height: 260px" alt="laptop")
    div.adfooter-text.text-center NEXD Campaign Manager eliminates complexity from the ad production process, without sacrificing any of the creativity today's audience demands.
</template>

<script>
export default {
  name: 'AdFooter',
};
</script>
