import Subscriber from '@libs/Subscriber';

class UsersService extends Subscriber {
  subscribe(cb, vnode = null, developer = false) {
    if (this.data === undefined) {
      this.load(developer);
    }
    super.subscribe(cb, vnode);
  }

  load(developer = false) {
    if (developer) {
      return super.load('users/devs');
    }

    return super.load('v2/users?withme');
  }

  async get(id) {
    if (!id) return null;

    if (this.data === undefined) {
      await this.load();
    }

    return Promise.resolve(this.data.find(item => item.user_id === id));
  }
}

export default new UsersService();
