export default value => {
  if (!value || !value?.trim()?.length) return 'AA';

  const splitted = value.split(' ');

  const firstname = splitted[0];
  // make sure to take the last element, some people may have multiple names
  const lastname = splitted.pop();

  let initials = firstname[0];

  if (firstname !== lastname) {
    initials += lastname[0];
  }

  return initials;
};
