import CapitalizeFirstLetterFilter from './CapitalizeFirstLetterFilter';

function getNumberString(number) {
  switch (number) {
    case 1:
      return 'first';
    case 2:
      return 'second';
    case 3:
      return 'third';
    case 4:
      return 'fourth';
    case 5:
      return 'fifth';
    case 6:
      return 'sixth';
    default:
      return 'other';
  }
}

export default (value, capitalize = false) => {
  const string = getNumberString(value);

  if (capitalize) {
    return CapitalizeFirstLetterFilter(string);
  }

  return string;
};
