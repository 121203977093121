export default {
  inserted: el => {
    el.addEventListener('keypress', event => {
      let keyCode = event.keyCode || event.which;
      // prevent if its not a number
      if (keyCode < 48 || keyCode > 57) {
        event.preventDefault();
      }
    });
  },
};
