/**
 * @example v-tutorial="SOME_KEY"
 */

import { VIEW } from '@master/constants';

export default {
  inserted: async (el, binding) => {
    let value = null;

    const key = binding?.value;

    if (Array.isArray(key)) {
      if (key.includes('DEMO_CAMPAIGN')) {
        value = 'fO09';
      } else if (key.includes('DEMO_FINISHED_CAMPAIGN')) {
        value = '54PW';
      }
    } else {
      switch (key) {
        case VIEW.DASHBOARD:
          value = 'oGdK';
          break;
        case VIEW.CAMPAIGNS:
          value = 'S3Z8';
          break;
        case VIEW.GROUP_ASSETS:
          value = 'GrH6';
          break;
        case 'export-button':
          value = 'pkW5';
          break;
        case 'sidebar-analytics':
          value = 'mBdM';
          break;
      }
    }

    if (value != null) {
      el.setAttribute('data-tutorial', value);
    }
  },
};
