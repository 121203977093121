import Subscriber from '@libs/Subscriber';
import { TYPE, SIZE } from '@master/constants';

class CreativeSizeService extends Subscriber {
  #creative = null;
  #expanded = false;
  #valid_sizes = null;

  constructor() {
    super();
    this.reset();
  }

  reset() {
    this.data = {
      warning: {
        width: false,
        height: false,
        message: null,

        expanded: {
          width: false,
          height: false,
          message: null,
        },
      },
      has_warning: false,
    };
  }

  validate(creative) {
    const valid = this.isValid(creative);

    if (creative?.expanded != null) {
      const valid_expanded = this.isValid(creative.expanded, true);
      return valid && valid_expanded;
    }

    return valid;
  }

  isValid(creative, expanded = false) {
    if (!creative) {
      return false;
    }

    this.#creative = creative;
    this.#expanded = expanded;

    this.#initValidSizes();
    const valid_w = this.#isValidDimension('width');
    const valid_h = this.#isValidDimension('height');
    const valid = valid_w && valid_h;

    this.data.has_warning = !valid;
    this.#getWarningMessage();
    super.sendAll();
    return valid;
  }

  #isValidDimension(key) {
    const dim = parseInt(this.#creative[key] ?? 0, 10);
    const valid = dim >= this.#valid_sizes[key].min && dim <= this.#valid_sizes[key].max;

    if (this.#expanded) {
      this.data.warning.expanded[key] = !valid;
    } else {
      this.data.warning[key] = !valid;
    }

    return valid;
  }

  #initValidSizes() {
    if (this.#creative.type === TYPE.VAST && this.#creative.settings?.ctv_format) {
      this.#valid_sizes = {
        width: {
          min: 360,
          max: SIZE.MAX_FILE_DIMENSION,
        },
        height: {
          min: 360,
          max: SIZE.MAX_FILE_DIMENSION,
        },
      };
    } else {
      this.#valid_sizes = {
        width: {
          min: SIZE.CREATIVE_VALIDATION_MIN,
          max: SIZE.CREATIVE_VALIDATION,
        },
        height: {
          min: SIZE.CREATIVE_VALIDATION_MIN,
          max: SIZE.CREATIVE_VALIDATION,
        },
      };
    }
  }

  #getWarningMessage() {
    let obj = this.data.warning;

    if (this.#expanded) {
      obj = this.data.warning.expanded;
    }

    obj.message = null;

    const key = this.#getWarningKey(obj);

    if (key != null) {
      obj.message = `Please define ${key} of the ${this.#expanded ? 'expanded ' : ''}creative (between ${this.#valid_sizes?.width?.min} and ${this.#valid_sizes?.width?.max} px)`;
    }
  }

  #getWarningKey(obj) {
    if (obj.width && obj.height) {
      return 'width & height';
    } else if (obj.width) {
      return 'width';
    } else if (obj.height) {
      return 'height';
    }
  }
}

export default new CreativeSizeService();
