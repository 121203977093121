class SDKPlugin {
  #secret;
  #clientId;

  /**
   * @param {HeadersInit} headers
   * @returns {void}
   */
  setHeaders(headers) {
    const params = new URLSearchParams(window.location.search);

    if (params.has('sdkKey') && params.has('clientId')) {
      this.#secret = params.get('sdkKey');
      this.#clientId = params.get('clientId');
    }

    if (this.#secret != null && this.#clientId != null) {
      headers.set('Nxd-SDK-key', this.#secret);
      headers.set('Nxd-Client-Id', this.#clientId);
    }
  }

  /**
   * @param {{ action: string, data: Object }} obj
   * @returns {void}
   */
  sendMessage(obj) {
    window.parent.postMessage(
      {
        name: 'nexd.sdk',
        ...obj,
      },
      '*',
    );
  }
}

export default new SDKPlugin();
