import Subscriber from '@libs/Subscriber';
import { clone } from '@helpers/Global';

class UIGroupService extends Subscriber {
  subscribe(cb, vnode = null) {
    if (this.data === undefined) {
      this.load();
    }
    super.subscribe(cb, vnode);
  }

  load() {
    return super.load('v2/ui/groups');
  }

  getUIGroup(id = null) {
    if (id != null && this.data?.[id] != null) {
      return clone(this.data[id]);
    }
    return null;
  }
}

export default new UIGroupService();
