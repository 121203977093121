import moment from 'moment';
import $user from '@master/Services/UserService';

import { DATE_FORMAT_OPTIONS, DEFAULT_DATE_FORMAT } from '@master/constants';

export default (value, options = null) => {
  if (value == null) return options?.fallback ?? '-';

  let datefn = moment;
  if (options?.utc) {
    datefn = datefn.utc;
  }

  options = options ?? {};

  if (!options.format) {
    options.format = DEFAULT_DATE_FORMAT;

    // validate user format
    if (DATE_FORMAT_OPTIONS.includes($user.getDateFormat())) {
      options.format = $user.getDateFormat();
    }

    // our options are in lowercase
    // JS formats are in uppercase
    options.format = options.format.toUpperCase();

    if (options?.time) {
      options.format += ' HH:mm';
    }
  }

  return datefn(value, 'X').format(options.format);
};
