import $http from '@master/Services/HttpService';

class Cache {
  #cache;

  constructor() {
    this.#cache = new Map();
  }

  /**
   *
   * @param {string} key - URL of the request
   * @param {?object} options - Options for the request
   * @returns {Promise<unknown|unknown[]>} - Promise with the response from the request
   */
  async get(key, options = {}) {
    if (this.#cache.has(key)) {
      this.debug();
      return this.#cache.get(key);
    }

    try {
      const response = await $http.get(key, options);
      this.#cache.set(key, response);
      this.debug();
      return response;
    } catch (error) {
      throw error;
    }
  }

  /**
   * Remove item from cache
   * @param {string} key - URL of the request
   * @returns {void}
   */
  remove(key) {
    this.#cache.delete(key);
    this.debug();
  }

  /**
   * Clear cache
   * @returns {void}
   */
  clear() {
    this.#cache.clear();
  }

  /**
   * Update cache entry
   * @param {string} key - URL of the request
   * @param {Function} callback - Function to update the cache entry
   * @returns {void}
   */
  update(key, value) {
    if (!this.#cache.has(key)) {
      return;
    }

    this.#cache.set(key, value);
    this.debug();
  }

  /**
   * Debug cache entries
   */
  debug() {
    if (window.debug) {
      console.log('%c[CACHE]', 'background: green; color: white', this.#cache);
    }
  }
}

export default new Cache();
