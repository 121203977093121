let el;
let collapse = false;
let transition = 300;

const show = () => {
  el.style.maxHeight = el.firstChild.clientHeight + 'px';
};

const hide = () => {
  // make sure max height exsists before collapsing
  el.style.maxHeight = el.firstChild.clientHeight + 'px';
  setTimeout(() => {
    el.style.maxHeight = 0;
    el.dispatchEvent(new CustomEvent('change', { detail: true }));
  });
};

const toggle = () => {
  if (collapse === false) {
    show();
  } else {
    hide();
  }
};

export default {
  inserted: (node, binding) => {
    el = node;
    el.style.overflow = 'hidden';
    el.style.transition = `${transition}ms ease-in-out`;
    collapse = binding.value;
  },
  update: (node, binding) => {
    if (binding.value !== collapse) {
      collapse = binding.value;
      toggle();
    }
  },
};
