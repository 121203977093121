import $http from '@master/Services/HttpService';

export default class Queue {
  #queue;
  #cache;

  constructor() {
    this.#queue = new Map();
    this.#cache = new Map();
  }

  /**
   *
   * @param {string} key - URL of the request
   * @param {?object} options - Options for the request
   * @returns {Promise<any>} - Promise with the response from the request
   */
  get(key, options = {}) {
    if (this.#cache.has(key)) {
      return Promise.resolve(this.#cache.get(key));
    }

    if (this.#queue.has(key)) {
      return this.#queue.get(key);
    }

    const promise = new Promise((resolve, reject) => {
      $http
        .get(key, options)
        .then(response => {
          this.#cache.set(key, response);
          resolve(response);
        })
        .catch(error => reject(error));
    });

    this.#queue.set(key, promise);

    return promise;
  }

  /**
   * Clears the queue and cache
   * @returns {void}
   */
  clear() {
    this.#queue.clear();
    this.#cache.clear();
  }
}
