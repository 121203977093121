<template lang="pug">
div#app-wrapper
  FilesView
  AdFooter
</template>

<script>
import AdFooter from '@components/AdFooter.vue';
import FilesView from '@components/FilesView.vue';

export default {
  name: 'Landing',

  components: {
    AdFooter,
    FilesView,
  },
};
</script>
