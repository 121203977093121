/**
 * @example v-user-guiding="'new-campaign-button'"
 */
export default {
  inserted: async (el, binding) => {
    const identifier = binding?.value;

    if (identifier != null) {
      el.onmouseup = () => {
        if (window.userGuiding && process.env.VUE_APP_ENV === 'prd') {
          window.userGuiding?.track(identifier);
        } else if (window.debug) {
          console.log(
            '%c[UG]',
            'background: purple; color: white',
            '\t',
            identifier,
          );
        }
      };
    }
  },
};
