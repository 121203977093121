import AssetHelper from '@helpers/Asset';
import BackgroundLayer from './BackgroundLayer';
import Layer from './Layer';
import Slot from './Slot';

export default class Drawable {
  #is_hotspot_main = false;

  /**
   * @param {Slot} slot
   * @param {String} fallback_fill
   */
  constructor(slot, fallback_fill) {
    this.asset_id = slot.asset_id;
    this.scale = slot.user_scale;
    this.width = slot.getWidth();
    this.height = slot.getHeight();
    this.x = slot.getX();
    this.y = slot.getY();

    this.uri = slot.image_uri ?? null;
    this.blob = slot.blob ?? null;

    this.fill = fallback_fill ?? '#BECBDC';
    this.related = [];
  }

  /**
   * @param {Array} additionals
   * @param {string} active_asset_id
   * @returns
   */
  claimRelatedAssets(additionals, active_asset_id) {
    // related assets mostly have the same suffix eg video controls
    this.related = additionals.filter(({ asset_id }) => asset_id !== active_asset_id && asset_id.endsWith(this.asset_id));
    return additionals.filter(({ asset_id }) => !asset_id.endsWith(this.asset_id));
  }

  /**
   * @param {Array} additionals
   * @param {string} active_asset_id
   * @returns
   */
  claimRelatedHotspotAssets(additionals, active_asset_id) {
    this.#is_hotspot_main = true;
    // hs have the same prefix with asset id
    const pattern = this.asset_id.replace('_main', '');
    // make sure pin is not considered as part of hotspot, as with main asset, pin is below the hotspot and/or not visible ever
    // but pin is drawn by itself as an overlay
    this.related = additionals.filter(({ asset_id }) => asset_id !== active_asset_id && asset_id.includes(pattern) && !AssetHelper.isCustomHotspotPin(asset_id));
    return additionals.filter(({ asset_id }) => !asset_id.includes(pattern));
  }

  load() {
    return new Promise(resolve => {
      if (this.uri == null && this.blob == null) {
        return resolve();
      }

      this.img = new Image();
      this.img.crossOrigin = 'Anonymous';

      let src = null;
      if (this.uri) {
        const hash = '' + Math.round(Math.random() * 1000000000);
        src = this.uri.split('?')[0] + '?' + hash;
      } else if (this.blob) {
        src = this.blob;
      }

      this.img.src = src;

      this.img.onload = () => {
        resolve();
      };

      // fallback on image error, to skip the file
      this.img.onerror = () => {
        this.img = null;
        resolve();
      };
    });
  }

  /**
   * @param {BackgroundLayer} layer
   */
  async draw(layer) {
    await this.load();

    const ctx = layer.ctx;

    const x = this.x - this.width * 0.5;
    const y = this.y - this.height * 0.5;
    if (this.img == null) {
      ctx.fillStyle = this.fill;
      ctx.fillRect(x, y, this.width, this.height);
    } else {
      ctx.drawImage(this.img, x, y, this.width, this.height);
    }

    // by default put related assets to the same layer with same conf
    let target_layer = layer;

    // non hotspot assets are size and position related to the parent asset
    // unless we have hotspot video control, then
    if (!this.#is_hotspot_main) {
      target_layer = new Layer(this.width, this.height);
    }

    for (const related_asset of this.related) {
      const slot = new Slot(related_asset.asset_id, related_asset);
      slot.adjustForOverlay(target_layer);

      // hotspot main related assets are drawn as overlays
      if (!this.#is_hotspot_main) {
        slot.adjustForParent(this);
      }

      // since the code above dont handle the case where hotspot video controls are actually put on top of asset, not into placement
      // we handle it separately so the special case could be seen easier
      const is_hotspot_video_control = this.#is_hotspot_main && AssetHelper.isCustomVideoControlAsset(related_asset.asset_id);
      if (is_hotspot_video_control) {
        target_layer = new Layer(this.width, this.height);
        slot.adjustForOverlay(target_layer);
        slot.adjustForParent(this);
      }

      const drawable = target_layer.addAsset(slot);
      if (!drawable) {
        continue;
      }
      await drawable.draw(layer);
    }
  }
}
