import WhitelabelService from '@master/Services/WhitelabelService';
import { clone } from '@helpers/Global';
import { createButton, createButtons, types } from '@master/UI/Buttons/utils';

import styles from '@styles/modules/alert.module.scss';
import button_styles from '@master/UI/Buttons/Button.module.scss';

export function alert(Vue, message, heading = 'Watch out!', options) {
  options = clone(options ?? {});

  options.image = defaultImage(options);
  options.buttons = defaultButtons(options, true);

  return confirm(Vue, heading, message, options);
}

export function confirm(Vue, heading, message = null, options = {}) {
  /** blur active element */
  document?.activeElement?.blur();

  /** validate options */
  options = clone(options ?? {});
  options.image = defaultImage(options);
  options.buttons = defaultButtons(options);
  options.width = defaultWidth(options);

  /** create response as promise */
  let resolve = null;
  const promise = new Promise(_resolve => {
    resolve = _resolve;
  });

  /** create dialog element */
  let dialog = createElement('dialog', document.body, styles.alert);
  dialog.style.width = options.width;

  /** create header elements with content */
  let head = createElement('section', dialog, styles.header);
  if (!WhitelabelService.enabled()) {
    createElement('img', head, null).src = options.image;
  }
  createElement('span', head, null).textContent = heading;

  /** create content element */
  if (message != null) {
    createElement('section', dialog, styles.content).innerHTML = message;
  }

  if (Vue != null) {
    /** create buttons container element with buttons */
    let buttons = createButtons(Vue, dialog);
    buttons.style.width = '100%';

    for (const button of options.buttons) {
      buttons.appendChild(createButton(Vue, dialog, resolve, button));
    }
  } else {
    /** if Vue is undefined then create buttons manually, probably called in js files */
    createButtonsManually(dialog, resolve, options.buttons);
  }

  /** dialog native methods, open + when close then remove element */
  dialog.showModal();
  dialog.onclose = () => dialog.remove();
  dialog.onkeydown = event => {
    if (event.key === 'Enter') {
      resolve(true);
      dialog.close();
    }
  };

  return promise;
}

function defaultButtons(options, alert = false) {
  let buttons = [
    {
      type: 'primary',
      label: 'Close',
      outline: false,
      action: false,
    },
  ];

  if (!alert) {
    buttons = [
      { type: 'link-primary', label: 'Cancel', action: false },
      { type: 'primary', label: 'Confirm', action: true },
    ];
  }

  return serializeButtons(options?.buttons ?? buttons);
}

function defaultImage(options) {
  if (options.image) {
    if (options.image === 'success') {
      return window.__nexd.cdn + 'dist/nexd/imgs/icecream-success.svg';
    }
    if (options.image !== 'error') {
      return options.image;
    }
  }
  return window.__nexd.cdn + 'dist/nexd/imgs/roadblock.svg';
}

function defaultWidth(options) {
  return options?.width ?? '540px';
}

function createElement(element, parent, className = null) {
  let new_element = document.createElement(element);
  if (className != null) {
    new_element.className = className;
  }
  parent.appendChild(new_element);
  return new_element;
}

function serializeButtons(buttons) {
  return buttons.map(button => {
    const correct_type = types.includes(button?.type);
    const type = correct_type ? button?.type : 'primary';

    return {
      type,
      label: button?.label ?? 'Cancel',
      animate: !type.includes('link'),
      outline: button?.outline ?? false,
      action: button?.action === undefined ? false : button.action,
    };
  });
}

function createButtonsManually(dialog, resolve, buttons) {
  let buttons_container = createElement('section', dialog, button_styles.buttons);
  buttons_container.style.width = '100%';

  for (const button of buttons) {
    const button_class = button?.outline === true ? button_styles[`${button.type}-outline`] : button_styles[button.type];

    let button_element = createElement('button', buttons_container, `${button_styles.button} ${button_class}`);
    button_element.textContent = button.label;
    button_element.onclick = () => {
      resolve(button.action);
      dialog.close();
    };
  }
}
