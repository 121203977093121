import FixedElementService from '@master/Services/FixedElementService';

export default {
  inserted: async (el, binding) => {
    FixedElementService.inserted(el, binding.value);
  },

  update: async (el, binding) => {
    FixedElementService.update(el, binding.value);
  },

  unbind: async (el, binding) => {
    FixedElementService.unbind(el, binding.value);
  },
};
