import Router from 'vue-router';
import Vue from 'vue';
Vue.use(Router);

import UserService from '@master/Services/UserService';

import Landing from '@views/Landing.vue';
import Optimizer from '@views/Optimizer.vue';

const meta = {
  app_name: 'Optimizer',
  loaded: false,
};

const router = new Router({
  mode: process.env.NODE_ENV === 'development' ? 'hash' : 'history',
  routes: [
    {
      path: '/',
      name: 'landing',
      component: Landing,
      meta,
    },
    {
      path: '/:session_id',
      name: 'optimizer',
      component: Optimizer,
      meta,
    },
    {
      path: '*',
      redirect: { name: 'landing' },
    },
  ],
});

router.beforeEach(async (to, from, next) => {
  document.title = `${Vue.prototype.$whitelabel.title} | ${to.meta.app_name}`;

  if (!(await UserService.isAuthenticated())) {
    window.location.href = `${process.env.VUE_APP_CM}login/?ref=${encodeURIComponent(window.location.href)}`;
  } else if (!UserService.canUseOptimizerApp()) {
    window.location.href = `${process.env.VUE_APP_CM}`;
  } else {
    to.meta.loaded = true;
    next();
  }
});

export default router;
