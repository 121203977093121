import HttpService from '@master/Services/HttpService';

class Service {
  Vue;
  $http;
  router = null;
  loading = false;

  init(Vue, router = null) {
    this.Vue = Vue;

    // need to init HttpService here
    // because it uses vue imports and needs to be ready
    // maybe one day we refactor the service
    // so we can instantly set the http variable
    this.$http = HttpService;
    this.router = router;
  }
}

export default Service;
