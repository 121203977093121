<script>
import AssetHelper from '@helpers/Asset';
import FileUpload from '@_libs/FileUpload';

export default {
  name: 'Global',

  computed: {
    file_input_accept_extensions() {
      return AssetHelper.mimeToExtension(this.upload.acceptedMimes());
    },

    allowed_types() {
      return this.file_input_accept_extensions
        .split(', ')
        .map(ext => {
          if (ext === '.jpeg') return;
          return ext.replace('.', '').toUpperCase();
        })
        .join(' ');
    },

    allowed_size() {
      return this.upload.acceptedSize();
    },
  },

  data() {
    return {
      user: null,

      upload: new FileUpload(),
      upload_error_message: null,
    };
  },

  created() {
    this.$user.subscribe(user => {
      if (user) {
        this.user = user;
        this.upload.setUser(user);
      }
    }, this);
  },

  methods: {
    openFileInput() {
      if (this.$refs.input) {
        this.$refs.input.click();
      }
    },

    clearInput() {
      if (this.$refs.input) {
        this.$refs.input.value = '';
      }
    },
  },
};
</script>
