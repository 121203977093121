import FixedElementService from '@master/Services/FixedElementService';
import { FIXED_ELEMENT } from '@master/constants';

export default {
  inserted: async el => {
    FixedElementService.inserted(el, FIXED_ELEMENT.CONTENT);
  },

  update: async el => {
    FixedElementService.update(el, FIXED_ELEMENT.CONTENT);
  },

  unbind: async el => {
    FixedElementService.unbind(el, FIXED_ELEMENT.CONTENT);
  },
};
