import WhitelabelService from '@master/Services/WhitelabelService';

export default {
  inserted: (el, bind) => {
    const params = new URLSearchParams();
    const value = bind.value;

    // add appid to cachable path, so differnt app deafults woult not cache
    params.append('app', window.__nexd.appid);

    if (typeof value === 'object') {
      // object arg allows to manually define color (eg: non icon color)
      params.append('path', value.path);
      if (value.color) {
        params.append('color', value.color);
      }
    } else {
      // string arg will automatically take WL icon color
      params.append('path', value);
      if (WhitelabelService.enabled()) {
        const partner = WhitelabelService.getPartner();
        if (partner) {
          params.append('partner', partner);
        } else {
          params.append('host', window.location.host);
        }
      }
    }
    el.src = `${window.__nexd.api}cdn?${params.toString()}`;
  },
};
