<template lang="pug">
div.file-container
  div.files-list-row.single-file
    div.overflow-ellipsis {{ file.original_filename }}
    div {{ options.original.size | FileSize(2) }}
    div {{ options.optimized.size | FileSize(2) }}
    i.nexd-icon-16-check-mini.color-success(aria-hidden="true")

    div.flex.flex-justify-end
      div(v-if="!collapsed")
        i.nexd-icon-32-share.hover-primary(aria-hidden="true" @click="copyDownloadLink")
        i.nexd-icon-32-arrow-up-small.cursor-pointer(aria-hidden="true" @click="toggleCollapse")

      div.hover-primary(v-else @click="toggleCollapse")
        span Detailed view
        i.nexd-icon-32-arrow-up-small.rotate(aria-hidden="true")

  AssetOptimizer(
    v-if="!collapsed"
    :original="options.original"
    :optimized="options.optimized"
    :session_id="file.session_id"
    @change="optimizeChangeHandler"
  )

  div(ref="video_frame_drawer" style="display: none")
</template>

<script>
import { setClipboard } from '@helpers/Global';

import AssetOptimizer from '@master/UI/AssetOptimizer.vue';

export default {
  name: 'File',

  components: {
    AssetOptimizer,
  },

  props: {
    file: Object,
  },

  data() {
    return {
      collapsed: true,

      // basically UIM optimizer options, will be reactive as well
      options: {
        session_id: this.file.session_id,
        original: {
          size: this.file.original_filesize,
          uri: this.file.original_uri,
          url: this.file.original_url,
        },
        optimized: {
          size: this.file.optimized_filesize,
          uri: this.file.optimized_uri,
          url: this.file.optimized_url,
          quality: this.file.quality,
        },
      },
    };
  },

  methods: {
    toggleCollapse() {
      this.collapsed = !this.collapsed;
    },

    optimizeChangeHandler(result) {
      const { zip_size, original_total_size, optimized_total_size } = result;
      this.$emit('update', zip_size, original_total_size, optimized_total_size);
    },

    copyDownloadLink() {
      setClipboard(this.file.optimized_url)
        .then(_ => {
          this.$notifications.add('primary', 'File URL copied to clipboard');
        })
        .catch(error => {
          this.$notifications.add('warning', error);
        });
    },
  },
};
</script>
