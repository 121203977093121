export default {
  inserted: el => {
    // el is the target element (parent)
    el.style.position = 'relative';
    el.style.outline = 'unset';

    const popup = el.querySelector('.popup');

    if (popup === null) {
      return;
    }

    const input = popup.querySelector('input');

    el.onclick = e => {
      // if the click is inside the popup, do nothing
      if (popup.contains(e.target)) {
        return;
      }

      // if clicking el when popup is open, then close popup (toggle functionality)
      // target element can also be child of the el, then it must not be inside the popup (profile dropdown in navbar)
      if (
        popup.classList.contains('open') &&
        el.contains(e.target) &&
        !popup.contains(e.target)
      ) {
        return close();
      }

      el.classList.add('active');
      popup.classList.add('open');

      focusElement();

      if (input != null) {
        input.focus();
      }
    };

    if (input != null) {
      input.onblur = _ => {
        // timeout is needed to check if the next focused element is inside the popup
        // because otherwise onblur is triggered before the next element is focused, e.g. link click
        setTimeout(() => {
          // if the next focused element is a link, open link and then close the popup
          if (closeOnLinkClick()) {
            return;
          }

          // if the next focused element is outside of the el, close the popup
          if (!el.contains(document.activeElement)) {
            return close();
          }

          focusElement();
        }, 10);
      };
    }

    el.onblur = _ => {
      // timeout is needed to check if the next focused element is inside the popup
      // because otherwise onblur is triggered before the next element is focused, e.g. input focus
      setTimeout(() => {
        // if the next focused element is a link, open link and then close the popup
        if (closeOnLinkClick()) {
          return;
        }

        // if the next focused element is input, do nothing
        if (document.activeElement === input) {
          return;
        }

        close();
      }, 10);
    };

    el.onkeydown = e => {
      if (e.key === 'Escape') {
        close();
      }
    };

    function close() {
      el.classList.remove('active');
      popup.classList.remove('open');
    }

    function closeOnLinkClick() {
      if (document.activeElement.tagName === 'A') {
        document.activeElement.onclick = _ => close();
        return true;
      }

      return false;
    }

    function focusElement() {
      el.tabIndex = 0;
      el.focus();
    }
  },
};
