<template lang="pug">
div#app-wrapper
  FileUpload
  NexdInfo
  NexdAdvantages
  AdFooter
</template>

<script>
import AdFooter from '@components/AdFooter.vue';
import FileUpload from '@components/FileUpload.vue';
import NexdAdvantages from '@components/NexdAdvantages.vue';
import NexdInfo from '@components/NexdInfo.vue';

export default {
  name: 'Landing',

  components: {
    AdFooter,
    FileUpload,
    NexdAdvantages,
    NexdInfo,
  },
};
</script>
