<template lang="pug">
center.nexd-info-container
  div.row.nexd-info-wrap.nexd-info-second-block
    div.nexd-info-header.header-bigger The NEXD advantage
    div.col-6(v-for="advantage in advantages" :key="advantage.icon")
      img.mb-32(v-whitelabel-image="`dist/assets/optimizer/${advantage.icon}`" height="100" alt="NEXD advantage icon")
      div.nexd-info-label.label-bigger {{ advantage.title }}
      div.nexd-info-content {{ advantage.content }}
</template>

<script>
export default {
  name: 'NexdAdvantages',

  data() {
    return {
      advantages: [
        {
          icon: 'icon-rocket.svg',
          title: 'Super-fast',
          content: 'Our blazing-fast compression will optimize your image and video files in an instant',
        },
        {
          icon: 'icon-hand.svg',
          title: 'Easy-to-use',
          content: 'Enjoy our simple hassle-free, drag & drop interface. It just takes the click of a button and it’s done',
        },
        {
          icon: 'icon-stack.svg',
          title: 'Robust infrastructure',
          content: 'Asset Optimizer runs on enterprise-grade infrastructure, designed to handle millions of requests per minute',
        },
        {
          icon: 'icon-box.svg',
          title: "It's free!",
          content: 'Get access to best-in-class media optimization technology, without paying a penny',
        },
      ],
    };
  },
};
</script>
