import { b64toBlob } from '@helpers/Global';
import $http from '@master/Services/HttpService';

class AssetCollection {
  #key;
  #assets = [];

  constructor(key) {
    this.#key = key;
  }

  add(item) {
    let mime = item.type;
    if (mime === 'image/svg') {
      mime += '+xml';
    }

    const blob = b64toBlob(item.b64, mime);
    const file = new File([blob], item.filename, { type: mime });

    const asset = {
      filename: item.filename,
      mime: item.mime,
      file,
      blob: window.URL.createObjectURL(blob),
    };

    this.#assets.push(asset);
    return asset;
  }

  first() {
    return this.#assets[0] ?? null;
  }

  empty() {
    return this.first() == null;
  }

  get() {
    return this.#assets;
  }

  async load() {
    const path = `templates/files/${this.#key}`;
    return $http.get(path).then(response => {
      for (const item of response) {
        this.add(item);
      }
    });
  }
}

export default {
  collections: new Map(),

  get(key) {
    if (!this.collections.has(key)) {
      this.collections.set(key, new AssetCollection(key));
    }
    return this.collections.get(key);
  },
};
