import Subscriber from '@libs/Subscriber';
import { clone, trimQuery } from '@helpers/Global';

export default class QueryOptions extends Subscriber {
  #DEFAULT_LIMIT = 50;

  subscribe(cb, vnode = null) {
    super.subscribe(cb, vnode);
  }

  setDefaultLimit(limit) {
    this.#DEFAULT_LIMIT = limit;
  }

  get() {
    return this.data;
  }

  add(options) {
    this.data = {
      ...this.data,
      ...options,
    };
  }

  reset() {
    this.data = {
      limit: this.#DEFAULT_LIMIT,
      offset: 0,
    };
  }

  update() {
    this.data = {
      ...this.data,
      offset: (this.data?.offset ?? 0) + this.#DEFAULT_LIMIT,
    };
  }

  updateSorting(sort, reverse = false) {
    if (this.data == null) {
      this.reset();
    } else if (this.data?.sort !== sort) {
      // remove/reset order when sort changes
      this.data.order = undefined;
    }

    let data = clone(this.data);

    data = {
      ...data,
      limit: this.#DEFAULT_LIMIT,
      offset: 0,
      sort,
      order: this.#getNewOrder(reverse),
    };

    if (data?.order == null) {
      delete data.sort;
      delete data.order;
    }

    this.data = data;
  }

  updateSearch(search) {
    if (this.data == null) {
      this.reset();
    }

    if (!search || trimQuery(search) === '') {
      delete this.data.search;
    } else {
      this.data.search = search;
    }
  }

  #getNewOrder(reverse) {
    switch (this.data?.order) {
      case 'asc':
        return reverse ? 'desc' : null;
      case 'desc':
        return reverse ? null : 'asc';
      default:
        return reverse ? 'asc' : 'desc';
    }
  }
}
