/*
 * Directive made for VUE, that will append tooltip into the body (by default) or if param appendTo: 'parent' then will append to parent
 * usage simple:
 * v-tooltip="{value: 'Some small text for the tooltip'}"
 *
 * usage with template
 * v-tooltip="{template: 'templateKey'}"
 *
 * Templates are held in /cdn/src/vue/Templates/Tooltips.js
 *
 * Default position for tooltip is top, can change by adding position key inside the tooltip object
 * v-tooltip="{template: 'templateKey', position: 'left'}"
 */

import { searchLongStringsAndBreak } from '@helpers/Global';
import UserService from '@master/Services/UserService';
import WhitelabelService from '@master/Services/WhitelabelService';

const MAX_WIDTH = 240;

export default {
  inserted: (el, binding) => {
    if (!binding.value) return;
    el._tooltip = new Tooltip(el, binding);
  },

  update: (el, binding) => {
    if (!el._tooltip) return;

    el._tooltip.destroy();
    el._tooltip.init(binding);
  },

  unbind: el => {
    if (!el._tooltip) return;
    el._tooltip.destroy();
  },
};

import templates from '@master/Directives/Templates/Tooltips';

class Tooltip {
  constructor(el, binding) {
    this.el = el;
    this.init(binding);
  }

  init(binding) {
    this.position = binding.value?.position ?? 'top';
    this._position = this.position;
    this.value = binding.value?.value ?? '';
    this.template = binding.value?.template ?? null;
    this.class = binding.value?.class ?? null;
    this.appendTo = binding.value?.appendTo ?? 'body';
    this.public = binding.value?.public ?? false;
    this.align = binding.value?.align ?? 'center';

    this.align_class = `align-${this.align}`;

    if (this.appendTo !== 'body') {
      this.el.style.position = 'relative';
    }

    this.width = binding?.value?.width ?? null;
    this.templateHTML = null;
    this.hoverOnElement = false;
    this.hoverOnTooltip = false;
    this.tooltip = null;

    if (this.template != null) {
      // find template
      const query = this.template.split('.');
      this.templateHTML = templates;
      for (const str of query) {
        this.templateHTML = this.templateHTML[str];
      }
    }
    this.addEvents();

    if (binding.value?.debug === true) {
      this.show();
    }
  }

  createTooltip() {
    this.tooltip = document.createElement('div');
    this.tooltip.classList.add('tooltip');

    let html = '';
    this.value = this.validateString(this.value);
    if (this.templateHTML != null) {
      let class_name = 'advanced';
      if (this.class != null) {
        class_name += ' ' + this.class;
      }

      let final_html = this.templateHTML;

      // if public paths eg shared analytics, remove read more linking to nexd.com
      if (this.public) {
        final_html = final_html.replace(/<a.+href="(https?:\/\/)?.+\.nexd\..+">.+<\/a>/gi, '');
      }

      html = `<div class="${class_name}">
        ${final_html}
      </div>`;
    } else if (this.class != null) {
      html = `<div class="${this.class}">${this.value}</div>`;
    } else {
      if (this.value.replace != null) {
        this.value = this.value.replace(/_/g, ' ');
      }
      html = `<div class="simple">${this.value}</div>`;

      // simple tooltips have no pointer events
      this.tooltip.style.pointerEvents = 'none';
    }
    this.tooltip.innerHTML = html;
    this.tooltip.style.opacity = 0;
    this.tooltip.style.transition = 'opacity 0.3s';
    // position tooltip
    let { top, left } = this.getPosition();
    this.tooltip.style.top = top;
    this.tooltip.style.left = left;

    this.tooltip.classList.add(this._position);
    this.tooltip.classList.add(this.align_class);

    this.tooltip.addEventListener('mouseenter', () => {
      this.hoverOnTooltip = true;
    });
    this.tooltip.addEventListener(
      'mouseleave',
      event => {
        if (event.currentTarget.contains(event.relatedTarget)) {
          return;
        }
        this.hoverOnTooltip = false;
        setTimeout(() => {
          if (this.hoverOnElement === false) {
            this.hide();
          }
        });
      },
      true,
    );

    const link = this.tooltip.querySelector('.tooltip-link');
    if (link != null) {
      if (WhitelabelService.whitelabel?.is_whitelabel || UserService.isSDKUser()) {
        // For whitelabels we are removing all the links - so no reference for nexd.com CS-3148
        link.parentElement.removeChild(link);
      } else {
        link.addEventListener('click', () => {
          this.hide();
        });
      }
    }
  }

  checkLocation() {
    this._position = this.position;
    const parentPosition = this.el.getBoundingClientRect();
    const top = parentPosition.top;
    const maxTop = this.template != null ? 200 : 120;
    if (top < maxTop && this.position === 'top') {
      this._position = 'bottom';
    }
    const left = parentPosition.left;
    if (left < 200 && this.position === 'left') {
      this._position = 'right';
    }
    const right = window.innerWidth - parentPosition.left;
    if (right < 200 && this.position === 'right') {
      this._position = 'left';
    }
  }

  getPosition() {
    this.checkLocation();
    const parentPosition = this.el.getBoundingClientRect();
    let top = 0;
    let left = 0;
    if (this.appendTo === 'self') {
      // on self keep 0, 0
    } else {
      top = parentPosition.top + window.pageYOffset;
      left = parentPosition.left + window.pageXOffset;
    }

    let offsetX = 0;
    let offsetY = 0;
    if (this._position === 'bottom' || this._position === 'top') {
      if (this._position === 'bottom') {
        offsetY = parentPosition.height - 4;
      } else {
        offsetY = 4;
      }

      if (this.align === 'left') {
        offsetX = 0;
      } else if (this.align === 'right') {
        offsetX = parentPosition.width;
      } else if (this.align === 'center') {
        offsetX = parentPosition.width * 0.5;
      }
    } else if (this._position === 'left' || this._position === 'right') {
      offsetY = parentPosition.height * 0.5;
      if (this._position === 'right') {
        offsetX = parentPosition.width - 4;
      } else {
        offsetX = 4;
      }
    }

    return { top: top + offsetY + 'px', left: left + offsetX + 'px' };
  }

  show() {
    if ((this.value == null || this.value.trim() === '') && this.templateHTML == null) return;
    if (this.tooltip) return;

    this.createTooltip();

    // delay before triggering show, since quick hovers are requested not to show (for testing how it looks, since who am i to say this is shit and will seem like a glitch)
    const delay = 100;
    setTimeout(() => {
      // make sure the tooltip is not already destroyed
      if (!this.tooltip) return;

      if (this.appendTo === 'self') {
        this.el.appendChild(this.tooltip);
      } else {
        document.body.appendChild(this.tooltip);
      }

      // timeout to render HTML before fade in
      setTimeout(() => {
        // make sure the tooltip is not already destroyed
        if (!this.tooltip) return;

        if (this.width != null) {
          if (this.width === 'auto') {
            // auto width, remove min from css and cap by max
            this.tooltip.firstChild.style.width = this.width;
            this.tooltip.firstChild.style.minWidth = this.width;
            this.tooltip.firstChild.style.maxWidth = MAX_WIDTH + 'px';
          } else {
            this.tooltip.firstChild.style.width = this.width + 'px';
          }

          this.tooltip.firstChild.style.whiteSpace = 'normal';
        } else {
          const width = this.tooltip.clientWidth;
          if (width > MAX_WIDTH) {
            // if the width is bigger than max width, add maxwidth class, so it will break the lines
            const with_body = this.tooltip.querySelector('.tooltip-body');
            if (!with_body) {
              // tooltip templates have body limited by width
              // limit tooltip width itself if theres no body from template
              this.tooltip.firstChild.style.maxWidth = MAX_WIDTH + 'px';
            }
            this.tooltip.firstChild.style.whiteSpace = 'normal';
          } else {
            // smaller tooltips will always have center text
            this.tooltip.firstChild.style.textAlign = 'center';
          }
        }

        // const min = 100;
        // animate tooltip in
        this.tooltip.style.opacity = 1;
      });
    }, delay);
  }

  hide() {
    // make sure the tooltip is not already destroyed
    if (!this.tooltip) return;

    if (this.tooltip.parentNode) {
      this.tooltip.parentNode.removeChild(this.tooltip);
    }
    this.tooltip = null;
  }

  addEvents() {
    this.el.addEventListener('mouseenter', () => {
      this.hoverOnElement = true;
      this.show();
    });
    this.el.addEventListener('mouseleave', event => {
      if (event.currentTarget.contains(event.relatedTarget)) {
        return;
      }
      this.hoverOnElement = false;
      setTimeout(() => {
        if (this.hoverOnTooltip === false) {
          this.hide();
        }
      });
    });
  }

  destroy() {
    this.hide();
    this.value = null;
  }

  validateString(string) {
    // if string is html, empty or not existing then return original value.
    if (string.startsWith('<') || string === '' || string == null) return string;

    // if string has words longer than 29 chars then modify string to fit correctly in tooltip container.
    if (string.split(' ').some(word => word.length > 29)) {
      return searchLongStringsAndBreak(string);
    }

    return string;
  }
}
