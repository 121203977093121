import Drawable from './Drawable';
import Slot from './Slot';

export default class Layer {
  #reserved = [];
  constructor(width, height) {
    this.width = width;
    this.height = height;

    /** @type {Drawable[]} */
    this.assets = [];
  }

  /**
   * @param {Slot} slot
   * @param {boolean} check_reserved_spot
   */
  addAsset(slot, check_reserved_spot = true) {
    const drawable = new Drawable(slot);
    if (check_reserved_spot && this.#isReservedPosition(drawable)) {
      return null;
    }
    this.assets.push(drawable);
    this.reserveAssetPosition(drawable.x, drawable.y);
    return drawable;
  }

  reserveAssetPosition(x, y) {
    this.#reserved.push({ x, y });
  }

  /**
   * @param {Drawable} drawable
   */
  #isReservedPosition(drawable) {
    // never reserve overlays
    if (this.name === 'Overlay') {
      return false;
    }
    return this.#reserved.find(pos => drawable.x === pos.x && drawable.y === pos.y) != null;
  }
}
