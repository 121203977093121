import { FIXED_ELEMENT } from '@master/constants';

class FixedElementService {
  #order = Object.values(FIXED_ELEMENT);
  #elements = [];
  #content = null;
  #sticky_content = null;

  inserted(vnode, key) {
    if (key == null) {
      return;
    }

    switch (key) {
      case FIXED_ELEMENT.CONTENT:
        this.#content = vnode;
        break;
      case FIXED_ELEMENT.STICKY_CONTENT:
        this.#sticky_content = vnode;
        break;
      default:
        this.#elements[this.#order.indexOf(key)] = vnode;
        break;
    }

    this.update();

    window.onresize = () => {
      this.update();
    };
  }

  update() {
    this.#calculate();
  }

  unbind(vnode, key) {
    if (key == null) {
      return;
    }
    if (![FIXED_ELEMENT.CONTENT, FIXED_ELEMENT.STICKY_CONTENT].includes(key)) {
      this.#elements.splice(this.#elements.indexOf(vnode), 1);
    }
    this.update();
  }

  #calculate() {
    let size = 48;

    for (const element of this.#elements) {
      if (element != null) {
        element.style.setProperty('top', `${size}px`);
        size += element?.clientHeight ?? 0;
      }
    }

    this.#calculateContent(size);
    this.#calculateStickyContent(size);
  }

  #calculateContent(size) {
    if (this.#content == null) return;

    this.#content.style.setProperty('position', 'relative');
    this.#content.style.setProperty('margin-top', `${size - 48}px`);
  }

  #calculateStickyContent(size) {
    if (this.#sticky_content == null) return;

    this.#sticky_content.style.setProperty('top', `${size + 16}px`);
  }
}

export default new FixedElementService();
