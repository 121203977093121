<script>
import { PERMISSION } from '@master/constants';

export default {
  computed: {
    can_read_group_content() {
      return this.$user.havePermission(PERMISSION.READ_CONTENT);
    },
    can_manage_group_content() {
      return this.$user.havePermission(PERMISSION.MANAGE_CONTENT);
    },
    can_read_group_details() {
      return this.$user.havePermission(PERMISSION.READ_ORAGNIZATION);
    },
    can_manage_group_details() {
      return this.$user.havePermission(PERMISSION.MANAGE_ORAGNIZATION);
    },
    can_read_group_billing() {
      return this.$user.havePermission(PERMISSION.READ_BILLING);
    },
    can_manage_group_billing() {
      return this.$user.havePermission(PERMISSION.MANAGE_BILLING);
    },
  },
};
</script>
