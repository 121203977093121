import '@root/src/styles/main.scss';

import Vue from 'vue';
import App from './App.vue';
import router from './router/Router';
import WhitelabelService from '@master/Services/WhitelabelService';
import constants from '@master/constants';

import GlobalServicePlugin from '@master/Plugins/GlobalServicePlugin';
Vue.use(GlobalServicePlugin, router);

import NexdFilters from '@master/Plugins/NexdFilters';
Vue.use(NexdFilters);

import NexdDirectives from '@master/Plugins/NexdDirectives';
Vue.use(NexdDirectives);

function run() {
  new Vue({
    router,
    render: h => h(App),
  }).$mount('#app');
}

constants.ready(_ => {
  WhitelabelService.ready(whitelabel => {
    Vue.prototype.$whitelabel = whitelabel;
    run();
  });
});
