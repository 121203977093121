<template lang="pug">
div.file-upload-container(ref="file-upload-container")
  div.px-16.flex.flex-align-center.flex-justify-center.flex-direction-column.row-gap-32
    center
      h1.mb-16.file-upload-header.clarika Optimize your media here
      div.file-upload-info.clarika Whether it's in ads, product images or even background video, smaller files use less bandwidth and save money! Also faster load time keeps your customers happy.
    center
      Button(type="primary" :animate="true" label="Choose files" @click="openFileInput")
      span.ml-16.fs-14.clarika.fw-300 Or drag and drop them here
      input#file-upload(ref="input" type="file" :accept="file_input_accept_extensions" multiple @change="fileChangeHandler")

    center.color-red-primary.clarika(v-if="upload_error_message != null")
      div.fw-500 Whoops!
      div.fs-14.mt-4 {{ upload_error_message }}

    div.row.clarika.fs-14
      div.col-12
        div.fw-200.color-gray-800.text-right Allowed file types:
        div.fw-500.mt-8.color-primary.text-right {{ allowed_types }}

      div.col-12
        div.fw-200.color-gray-800 Maximum asset size:
        div.fw-500.mt-8.color-primary {{ allowed_size }} MB

    center(v-if="user != null")
      img(v-whitelabel-image="'dist/assets/optimizer/scroll-icon.svg'" alt="scroll-icon")

  div(v-for="side of ['top-left', 'bottom-left', 'top-right', 'bottom-right']" :key="side" :class="`file-arrow ${side}`")

  div.file-upload-dropbox(v-if="dropbox_visible || uploading")
    div.file-upload-overlay
    div.file-dropbox-content(v-if="!uploading")
      i.nexd-icon-16-arrow-down1
      div Drop your assets here

    div.file-dropbox-content(v-else)
      div.dropbox-loader-container
        div.dropbox-loader
        div.dropbox-loader-counter {{ files_count }}
      div Uploading files
</template>

<script>
import GlobalMixins from '@mixins/Global.vue';

import Button from '@master/UI/Buttons/Button.vue';

export default {
  name: 'FileUpload',

  mixins: [GlobalMixins],

  components: {
    Button,
  },

  data() {
    return {
      dropbox_visible: false,
      uploading: false,
      files_count: 0,
      upload_error_message: null,
    };
  },

  mounted() {
    document.body.addEventListener('dragover', this.dragOverHandler, false);
    document.body.addEventListener('drop', this.dropHandler, false);
    document.body.addEventListener('dragleave', this.dragLeaveHandler, false);
  },

  beforeDestroy() {
    document.body.removeEventListener('dragover', this.dragOverHandler, false);
    document.body.removeEventListener('drop', this.dropHandler, false);
    document.body.removeEventListener('dragleave', this.dragLeaveHandler, false);
  },

  methods: {
    dragOverHandler(e) {
      e.preventDefault();
      this.dropbox_visible = true;
    },

    dropHandler(e) {
      e.preventDefault();
      this.fileChangeHandler({ target: { files: e.dataTransfer.files } });
      this.dropbox_visible = false;
    },

    dragLeaveHandler(e) {
      e.preventDefault();
      if (!e.currentTarget.contains(e.relatedTarget)) {
        this.dropbox_visible = false;
      }
    },

    fileChangeHandler(event) {
      this.upload
        .fileChangeHandler(event)
        .then(files => {
          this.sendFiles(files);
        })
        .catch(error => {
          this.upload_error_message = error.message;
        });
    },

    sendFiles(files) {
      if (this.uploading) {
        return;
      }

      this.files_count = files.length;
      this.uploading = true;

      this.$http
        .post('tools/optimizer', files, { notification: false })
        .then(response => {
          this.$router.push({
            name: 'optimizer',
            params: { session_id: response.id },
          });
        })
        .catch(_ => {
          this.upload_error_message = 'Failed to initialize optimizer session. Please try again.';
        })
        .finally(_ => {
          this.clearInput();
          this.uploading = false;
        });
    },
  },
};
</script>
