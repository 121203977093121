import { SIZE, TYPE } from '@master/constants';
import AssetHelper from '@helpers/Asset';
import CustomAssetService from '@master/Services/Cache/CustomAssetService';

import BackgroundLayer from './BackgroundLayer';
import TemplateHandler from './TemplateHandler';
import Drawable from './Drawable';
import LayoutHandler from './LayoutHandler';
import MainHandler from './MainHandler';
import Slot from './Slot';

const DEFAULT_PAGINATION_ARROW_SIZE = 40;
const DEFAULT_HS_CLOSE_SIZE = 62;

export default class Background {
  #creative;

  /** @type {MainHandler} */
  #handler;
  // when enabled, will append generated images to document.body
  #debug = false;
  #onDrawCallback = null;

  #active_asset_id;

  constructor(creative) {
    this.#creative = creative;

    const dimensions = this.#getDimensions();
    this.width = dimensions.width;
    this.height = dimensions.height;

    this.layers = {
      Background: new BackgroundLayer('Background', this.width, this.height, 400),
      Assets: new BackgroundLayer('Assets', this.width, this.height, 550),
      Overlay: new BackgroundLayer('Overlay', this.width, this.height, 1200),
      Hotspot: new BackgroundLayer('Hotspot', this.width, this.height, 1200),
    };
  }

  getCreative() {
    return this.#creative;
  }

  setActiveAssetId(active_asset_id) {
    this.#active_asset_id = active_asset_id;
  }

  getActiveAssetId() {
    return this.#active_asset_id;
  }

  isActiveHotspot() {
    return AssetHelper.isCustomHotspot(this.getActiveAssetId()) || AssetHelper.isCustomHotspotVideoAsset(this.getActiveAssetId());
  }

  isActiveHotspotMain() {
    return AssetHelper.isCustomHotspotMain(this.getActiveAssetId());
  }

  isActiveVideoControl() {
    return AssetHelper.isCustomVideoControlAsset(this.getActiveAssetId());
  }

  isActiveGesture() {
    return AssetHelper.isCustomGesture(this.getActiveAssetId());
  }

  onDraw(callback) {
    this.#onDrawCallback = callback;
  }

  async generate() {
    const promises = [];
    for (const layer of Object.values(this.layers)) {
      promises.push(layer.drawAssets());
    }

    await Promise.all(promises);

    if (this.#debug) {
      for (const layer of Object.values(this.layers)) {
        const img = document.createElement('img');
        img.alt = layer.name;
        img.src = layer.getImage();
        document.body.appendChild(img);
      }
    }

    if (this.#onDrawCallback) {
      this.#onDrawCallback(this.layers);
    }
  }

  /**
   * @param {Drawable[]} drawables
   */
  addPaginationArrows(drawables) {
    let left_arrow = null;
    let right_arrow = null;

    // arrows are found in additional overlays
    for (const slot of this.#handler.additional.slots.Overlay) {
      const asset_id = slot.asset_id;
      if (AssetHelper.isCustomArrowLeft(asset_id)) {
        left_arrow = slot;
      } else if (AssetHelper.isCustomArrowRight(asset_id)) {
        right_arrow = slot;
      }
    }

    if (!left_arrow) {
      left_arrow = new Slot('custom_arrow_left', CustomAssetService.get('custom_arrow_left').first());
      left_arrow.customSettings({ x: -this.#creative.width * 0.5 + DEFAULT_PAGINATION_ARROW_SIZE * 0.5, y: 0, width: DEFAULT_PAGINATION_ARROW_SIZE, height: DEFAULT_PAGINATION_ARROW_SIZE });
    }
    if (!right_arrow) {
      right_arrow = new Slot('custom_arrow_right', CustomAssetService.get('custom_arrow_right').first());
      right_arrow.customSettings({ x: this.#creative.width * 0.5 - DEFAULT_PAGINATION_ARROW_SIZE * 0.5, y: 0, width: DEFAULT_PAGINATION_ARROW_SIZE, height: DEFAULT_PAGINATION_ARROW_SIZE });
    }

    // draw arrows on bg asset layer because FW also draws arrows below overlays

    const left_arrow_drawable = this.layers.Assets.addAsset(left_arrow);
    if (left_arrow_drawable) {
      drawables.push(left_arrow_drawable);
    }
    const right_arrow_drawable = this.layers.Assets.addAsset(right_arrow);
    if (right_arrow_drawable) {
      drawables.push(right_arrow_drawable);
    }
  }

  /**
   * @param {Drawable[]} drawables
   */
  async addHotspotClose(drawables) {
    const active_asset_id = this.getActiveAssetId();

    const close_asset_id = active_asset_id.replace('_main', '_close');
    for (const drawable of drawables) {
      if (drawable.asset_id === close_asset_id) {
        return;
      }
    }

    const collection = CustomAssetService.get('custom_hs_close');
    if (collection.empty()) {
      await collection.load();
    }
    if (collection.empty()) {
      return;
    }

    const close = new Slot(close_asset_id, collection.first());
    close.customSettings({ x: this.#creative.width * 0.5 - DEFAULT_HS_CLOSE_SIZE * 0.5, y: -this.#creative.height * 0.5 + DEFAULT_HS_CLOSE_SIZE * 0.5, width: DEFAULT_HS_CLOSE_SIZE, height: DEFAULT_HS_CLOSE_SIZE });

    const close_drawable = this.layers.Overlay.addAsset(close);
    if (close_drawable) {
      drawables.push(close_drawable);
    }
  }

  /**
   * @returns {Drawable[]}
   */
  createDrawables() {
    const drawables = [];
    this.#createBackgroundDrawables(drawables);
    this.#createAssetDrawables(drawables);
    this.#createOverlayDrawables(drawables);

    return drawables;
  }

  /**
   * @returns {MainHandler}
   */
  getAssetHandler() {
    if (this.#creative.layout_id != null) {
      return this.#getLayoutHandler();
    }
    return this.#getTemplateHandler();
  }

  /**
   * @returns {MainHandler}
   */
  #getTemplateHandler() {
    this.#handler = new TemplateHandler(this);
    return this.#handler;
  }

  /**
   * @returns {MainHandler}
   */
  #getLayoutHandler() {
    this.#handler = new LayoutHandler(this);
    return this.#handler;
  }

  #getDimensions() {
    let width = this.#creative.width;
    let height = this.#creative.height;

    // fullscreen mobile placement
    if (this.#creative.type === TYPE.INTERSCROLLER) {
      // interscrollers placement size does not match the real ad / template size, which is high aspect ratio interstitial
      width = SIZE.INTERSTITIAL.WIDTH;
      height = SIZE.INTERSTITIAL.HEIGHT_HIGH_ASPECT;
    }

    return { width, height };
  }

  /**
   * @param {Drawable[]} drawables
   */
  #createBackgroundDrawables(drawables) {
    const handler = this.#handler;
    for (const slot of handler.slots.Background) {
      if (this.getActiveAssetId() === slot.asset_id) {
        continue;
      }

      const drawable = this.layers.Background.addAsset(slot);
      if (drawable) {
        drawables.push(drawable);
      }
    }
  }

  /**
   * @param {Slot[]} slots
   * @param {Drawable[]} drawables
   */
  #reserveMainAssetSlot(slots, drawables) {
    let active_asset_id = this.getActiveAssetId();
    const is_video_control = this.isActiveVideoControl();
    if (is_video_control) {
      // add video control parent asset first to reserve the slot
      active_asset_id = active_asset_id.replace(/custom_video_(play|mute)_/, '');
    }

    for (const slot of slots) {
      if (active_asset_id === slot.asset_id) {
        if (is_video_control) {
          // add video control parent to draw stack
          const drawable = this.layers.Assets.addAsset(slot);
          if (drawable) {
            drawables.push(drawable);
          }
        } else {
          // reserve the active asset position so it woul be empty
          this.layers.Assets.reserveAssetPosition(slot.getX(), slot.getY());
        }
        break;
      }
    }
  }

  /**
   * @param {Drawable[]} drawables
   */
  #createAssetDrawables(drawables) {
    const slots = this.#handler.slots.Assets.concat(this.#handler.additional.slots.Assets);
    const active_asset_id = this.getActiveAssetId();

    this.#reserveMainAssetSlot(slots, drawables);

    for (const slot of slots) {
      if (active_asset_id === slot.asset_id) {
        continue;
      }

      const drawable = this.layers.Assets.addAsset(slot);
      if (drawable) {
        drawables.push(drawable);
      }
    }
  }

  /**
   * @param {Drawable[]} drawables
   */
  #createOverlayDrawables(drawables) {
    const handler = this.#handler;

    // save overlay index in non reverse so we would know on which layer we will
    // draw regards active asset index being below or above
    let overlay_indexes = handler.slots.Overlay.map(slot => slot.asset_id).concat(handler.additional.slots.Overlay.map(slot => slot.asset_id).reverse());
    let active_index = overlay_indexes.indexOf(this.getActiveAssetId());

    // when active is video control, check the draw layer index/order by the parent asset instead
    // making sure the parent asset is drawn under the active asset
    if (this.isActiveVideoControl()) {
      const main_asset_id = this.getActiveAssetId().replace(/custom_video_(play|mute)_/, '');
      active_index = overlay_indexes.indexOf(main_asset_id);
    }

    let default_additional_overlay_layer = this.layers.Overlay;
    // if active is part of hotspot, everything is actually drawn below overlay layer
    if (this.isActiveHotspot() || this.isActiveGesture()) {
      default_additional_overlay_layer = this.layers.Assets;
    }

    // in reverse order
    for (const slot of handler.additional.slots.Overlay.concat(handler.slots.Overlay.reverse()).concat(handler.additional.slots.Hotspot)) {
      if (this.getActiveAssetId() === slot.asset_id || !slot.canDraw()) {
        continue;
      }

      let layer = default_additional_overlay_layer;
      const slot_index = overlay_indexes.indexOf(slot.asset_id);

      if (this.isActiveHotspotMain() && AssetHelper.isCustomHotspotClose(slot.asset_id)) {
        // hs close is always on top of hs main
        layer = this.layers.Overlay;
      } else if (active_index !== -1 && slot_index >= active_index) {
        // lower layers are always below
        layer = this.layers.Assets;
      }

      const drawable = layer.addAsset(slot, false);
      if (drawable) {
        drawables.push(drawable);
      }
    }
  }
}
