var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"asset-optimizer"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-16"},[_c('div',{staticClass:"row"},[(_vm.total_size != null)?_c('div',{staticClass:"col-8"},[_c('div',{staticClass:"file-data-info"},[_vm._v("New total load size:")]),_vm._v(" "),_c('div',{staticClass:"file-data-content color-success"},[_vm._v("\n            "+_vm._s(_vm._f("FileSize")(_vm.total_size,2))+"\n          ")])]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"col-8"},[_c('div',{staticClass:"file-data-info"},[_vm._v("Original asset size:")]),_vm._v(" "),_c('div',{staticClass:"file-data-content"},[_vm._v("\n            "+_vm._s(_vm._f("FileSize")(_vm.original.size,2))+"\n          ")])]),_vm._v(" "),(_vm.total_size == null)?_c('div',{staticClass:"col-8"},[_c('div',{staticClass:"file-data-info"},[_vm._v("Size decreased by:")]),_vm._v(" "),_c('div',{staticClass:"file-data-content"},[_vm._v(_vm._s(_vm._f("FileSize")(_vm.decreased,2)))])]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"col-8"},[_c('div',{staticClass:"file-data-info"},[_vm._v("Optimized size:")]),_vm._v(" "),_c('div',{staticClass:"file-data-content",class:{ 'color-success': _vm.total_size == null }},[_vm._v("\n            "+_vm._s(_vm._f("FileSize")(_vm.optimized.size,2))+"\n          ")])])])]),_vm._v(" "),(_vm.has_quality_slider && !_vm.visuals_hidden)?[_c('div',{staticClass:"col flex-grow"},[_c('div',{staticClass:"file-btn-container"},[_c('div',{staticClass:"file-data-info mr-8 mb-8 nowrap"},[_vm._v("Quality level:")]),_vm._v(" "),_c('Slider',{attrs:{"options":{
              min: 10,
              min_text: 'Low',
              max: 100,
              max_text: 'High',
              step: 10,
            }},on:{"change":_vm.change},model:{value:(_vm.quality),callback:function ($$v) {_vm.quality=$$v},expression:"quality"}})],1)])]:_vm._e()],2),_vm._v(" "),(!_vm.visuals_hidden)?[_c('div',{staticClass:"file-compare-wrapper",on:{"mousedown":_vm.startMove}},[(_vm.original_asset != null && _vm.optimized_asset != null)?[(_vm.updating)?_c('div',{staticClass:"file-compare-overlay"},[_c('div',{staticClass:"content"},[_vm._v("Working on it...")])]):_vm._e(),_vm._v(" "),_vm._m(0),_vm._v(" "),_c('div',{ref:"original",staticClass:"file-compare-container"},[_c('img',{attrs:{"src":_vm.original_asset,"alt":"original asset"},on:{"load":_vm.imageReady}})]),_vm._v(" "),_c('div',{ref:"optimized",staticClass:"file-compare-container optimized",style:({
            width: _vm.optimized_layer_width,
            'background-image': `url('${_vm.optimized_asset}')`,
            'background-size': `${_vm.image.width}px ${_vm.image.height}px`,
            'background-position': `right ${_vm.background_offset} center`,
          })},[_c('div',{staticClass:"file-compare-slider",on:{"mousedown":_vm.startMove}})])]:_vm._e()],2)]:_vm._e()],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"file-compare-head"},[_c('div',[_vm._v("Original asset")]),_vm._v(" "),_c('div',[_vm._v("Optimized asset")])])
}]

export { render, staticRenderFns }