import AssetHelper from '@helpers/Asset';
import { getBase64 } from '@helpers/Global';

export default class FileUpload {
  #user;

  setUser(user) {
    this.#user = user;
  }

  fileChangeHandler(e) {
    const promises = [];

    for (const file of e.target.files ?? []) {
      promises.push(this.#validateFile(file));
    }

    return Promise.all(promises);
  }

  acceptedMimes() {
    if (this.#user) {
      return [].concat(AssetHelper.getImageMimes(), AssetHelper.getVideoMimes(), AssetHelper.getHTMLMimes(), AssetHelper.getZIPMimes());
    }

    return AssetHelper.getImageMimes();
  }

  acceptedSize() {
    if (this.#user) {
      return 100;
    }

    return 20;
  }

  async #validateFile(file) {
    const is_supported_type = AssetHelper.isAcceptedMime(this.acceptedMimes(), file.type);
    const is_limit_exceeded = this.#acceptedLimit(file.size);

    if (is_supported_type && !is_limit_exceeded) {
      const b64_string = await getBase64(file);

      return {
        name: file.name,
        type: file.type,
        size: file.size,
        data: b64_string,
      };
    }

    let error = `Upload limit of ${this.acceptedSize()} MB exceeded`;

    if (!is_supported_type) {
      if (is_limit_exceeded) {
        error += ' and this file type is not supported';
      } else {
        error = 'This file type is not supported';
      }
    }

    throw new Error(`${error}!`);
  }

  #acceptedLimit(size) {
    return size / 1048576 > this.acceptedSize();
  }
}
