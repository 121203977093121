export default {
  inserted: (el, binding) => {
    if (binding?.value == null || typeof binding?.value !== 'object') return;

    el.addEventListener('keydown', event => {
      if (typeof binding?.value?.[event.key] === 'function') {
        binding?.value?.[event.key]();
      }
    });
  },
};
