import $http from '@libs/RequestCache';

const $whitelabel = {
  _default: {
    title: 'NEXD',
    icons_path: process.env.VUE_APP_DEFAULT_ICONS ? `${process.env.VUE_APP_DEFAULT_ICONS}/` : '_defaults/',
    logo: {
      mandatory: 'dist/assets/nexd-logo.svg',
      secondary: null,
      qr: 'nexd-logo.svg',
      coop: null,
    },
    color: {
      navbar_bg: '#d31e54',
      navbar_text: '#ffffff',
      splash_bg: '#d31e54',
      splash_text: '#ffffff',
      splash_btn_bg: '#ffffff',
      splash_btn_text: '#d31e54',
      landing_bg: '#d31e54',
      landing_main_color: '#d31e54',
      icons: '#d31e54',
    },
    analytics: {
      preview_links: true,
    },
    demos: [
      {
        campaign_id: '4ct5X7ospn',
        title: 'Infeed Layouts',
        icon: 'dist/assets/preview/landing/infeed.png',
      },
      {
        campaign_id: 'T1jybQVAh3',
        title: 'Fullscreen Layouts',
        icon: 'dist/assets/preview/landing/fullscreen.png',
      },
      {
        campaign_id: 'gdtcivOuLdJsLINn',
        title: 'Other Ad Types',
        icon: 'dist/assets/preview/landing/other.png',
      },
    ],
    greeting_texts: {
      mobile: {
        title: 'Welcome',
        subtitle: "We've pulled together some great examples to help inspire you!",
      },
      desktop: {
        top: {
          title: ['Welcome to', '%{TITLE}% Preview'],
          subtitle: 'Let %{TITLE}% do the magic for you!',
        },
        bottom: {
          title: 'Explore our layouts',
        },
      },
    },
    show_browser_frame: true,
    is_whitelabel: false,
  },

  whitelabel: null,

  _partner: null,
  _is_whitelabel_page: false,
  _ready: false,
  _ready_callbacks: [],
  _stylesheet: null,

  enabled() {
    return this._is_whitelabel_page;
  },

  getPartner() {
    return this._partner;
  },

  ready(cb) {
    if (this._ready) return cb(this.whitelabel);
    this._ready_callbacks.push(cb);
  },

  init(partner = null) {
    this._partner = partner;

    this.whitelabel = this._default;

    if (window._whitelabel) {
      return this.set(window._whitelabel);
    }

    let path = `${process.env.VUE_APP_API}public/whitelabel`;
    if (this._partner != null) {
      path += `/${this._partner}`;
    }
    path += `?appid=${process.env.VUE_APP_APPID}`;

    $http
      .get(path)
      .then(response => {
        this.set(response);
      })
      .catch(() => {
        /** suppress errors */
      });
  },

  set(whitelabel) {
    if (this._stylesheet) {
      this._stylesheet.remove();
    }

    if (whitelabel.style != null) {
      this._is_whitelabel_page = true;

      this._stylesheet = document.createElement('style');
      document.head.appendChild(this._stylesheet);
      this._stylesheet.sheet.insertRule(whitelabel.style);
    }

    if (whitelabel.settings != null) {
      this._is_whitelabel_page = true;

      // Since we don't wan't to specify differeny greetings in WL - we will use on those which are present
      let greetings = {};
      greetings.greeting_texts = { ...this._default.greeting_texts };
      if (whitelabel.settings.greeting_texts) {
        if (whitelabel.settings.greeting_texts.mobile) {
          greetings.greeting_texts.mobile = {
            ...whitelabel.settings.greeting_texts.mobile,
          };
        }
        if (whitelabel.settings.greeting_texts.desktop && whitelabel.settings.greeting_texts.desktop.top) {
          greetings.greeting_texts.desktop.top = {
            ...whitelabel.settings.greeting_texts.desktop.top,
          };
        }
        if (whitelabel.settings.greeting_texts.desktop && whitelabel.settings.greeting_texts.desktop.bottom) {
          greetings.greeting_texts.desktop.bottom = {
            ...whitelabel.settings.greeting_texts.desktop.bottom,
          };
        }
      }

      // Applying merged greetings on top of default + wl ones
      this.whitelabel = {
        ...this._default,
        ...whitelabel.settings,
        ...greetings,
        is_whitelabel: true,
      };
    }

    // not sure what to add as the identificator, currently aded the manifest link
    // if manifest is included, nothing will be added (eg preview, the backend adds everything for CM its just a temporary quick fix to have this like that right now)
    if (!document.querySelector('link[rel=manifest]')) {
      this.makeIcons();
      this.makeMeta();
    }

    this._ready = true;
    for (const cb of this._ready_callbacks) {
      cb(this.whitelabel);
    }
  },

  // currently intercom is never enabled for whitelabel sites
  intercomEnabled() {
    return !this.enabled();
  },

  get() {
    return this.whitelabel || this._default;
  },

  // copied from preview-2.0 server, when UI gets own backend this bottom part from here goes there
  getIconsDir() {
    const wl = this.whitelabel || this._default;
    return wl && wl.icons_path ? wl.icons_path : default_icons_dir;
  },
  getIconsPath() {
    return `${process.env.VUE_APP_CDN}dist/whitelabels/${this.getIconsDir()}`;
  },
  getColor() {
    const wl = this.whitelabel || this._default;
    return wl && wl.color && wl.color.navbar_bg ? wl.color.navbar_bg : default_color;
  },
  // except this, this is special as appendings to head
  makeIcons() {
    const head = document.head;
    const path = this.getIconsPath();
    const manifest = document.createElement('link');
    manifest.setAttribute('rel', 'manifest');
    manifest.setAttribute('href', `${path}manifest.json`);
    head.appendChild(manifest);

    const msapplication = document.createElement('meta');
    msapplication.setAttribute('name', 'msapplication-TileImage');
    msapplication.setAttribute('content', `${path}ms-icon-144x144.png`);
    head.appendChild(msapplication);

    const apple = ['57x57', '60x60', '72x72', '76x76', '114x114', '120x120', '144x144', '152x152', '180x180'];
    const android = ['192x192'];
    const favicon = ['32x32', '96x96', '16x16'];

    for (const size of apple) {
      const icon = document.createElement('link');
      icon.setAttribute('rel', 'apple-touch-icon');
      icon.setAttribute('sizes', `${size}`);
      icon.setAttribute('href', `${path}apple-icon-${size}.png`);
      head.appendChild(icon);
    }

    for (const size of android) {
      const icon = document.createElement('link');
      icon.setAttribute('rel', 'icon');
      icon.setAttribute('type', 'image/png');
      icon.setAttribute('sizes', `${size}`);
      icon.setAttribute('href', `${path}android-icon-${size}.png`);
      head.appendChild(icon);
    }

    for (const size of favicon) {
      const icon = document.createElement('link');
      icon.setAttribute('rel', 'icon');
      icon.setAttribute('type', 'image/png');
      icon.setAttribute('sizes', `${size}`);
      icon.setAttribute('href', `${path}favicon-${size}.png`);
      head.appendChild(icon);
    }
  },
  makeMeta() {
    const head = document.head;
    const color = this.getColor();

    const msapplication = document.createElement('meta');
    msapplication.setAttribute('name', 'msapplication-TileColor');
    msapplication.setAttribute('content', `${color}`);
    head.appendChild(msapplication);

    const theme = document.createElement('meta');
    theme.setAttribute('name', 'theme-color');
    theme.setAttribute('content', `${color}`);
    head.appendChild(theme);
  },
};

export default $whitelabel;
