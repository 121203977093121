export default {
  inserted: el => {
    el.onkeydown = e => {
      const keyCode = e.keyCode || e.which;
      // prevent minus
      if (keyCode === 189) {
        return false;
      }
    };
  },
};
