class PositionAware {
  constructor() {
    this.el = null;
    this.innerHTML = null;
  }

  set(el) {
    this.el = el;
    this.innerHTML = el?.innerHTML ?? null;
    this.init();
  }

  init() {
    if (!this.el) return;

    this.addStyles();
    this.toggleDisabledState();
  }

  addStyles() {
    this.el.classList.add('position-aware');
    this.el.style.overflow = 'hidden';
    this.el.style.position = 'relative';
    this.el.style.verticalAlign = 'middle';
  }

  toggleDisabledState() {
    if (this.el.disabled) {
      this.removeEvents(this.el);
    } else {
      this.addEvents(this.el);
    }
  }

  addEvents() {
    this.el.innerHTML = '';

    const text = this.#duplicateContent();
    this.el.appendChild(text);

    const bubble = this.#createBubble();
    this.el.appendChild(bubble);

    this.el.onmouseenter = e => {
      const relX = e.offsetX;
      const relY = e.offsetY;
      bubble.style.left = relX + 'px';
      bubble.style.top = relY + 'px';
      // make the circle bigger than the button, so no white edges would be seen
      bubble.style.width = this.el.offsetWidth * 3 + 'px';
      bubble.style.height = this.el.offsetWidth * 3 + 'px';
    };

    this.el.onmouseout = e => {
      const relX = e.offsetX;
      const relY = e.offsetY;
      bubble.style.left = relX + 'px';
      bubble.style.top = relY + 'px';
      bubble.style.width = '0px';
      bubble.style.height = '0px';
    };
  }

  removeEvents() {
    this.el.onmouseenter = undefined;
    this.el.onmouseout = undefined;
    this.el.innerHTML = this.innerHTML;
  }

  #createBubble() {
    let span = document.createElement('span');
    span.className = 'position-aware-span';
    span.style.position = 'absolute';
    span.style.borderRadius = '50%';

    const animationSpeed = Math.round((this.el.offsetWidth / 20) * 100);
    span.style.transition = `width ${animationSpeed}ms, height ${animationSpeed}ms`;
    span.style.transform = 'translate3d(-50%, -50%, 0)';
    span.style.zIndex = 1;
    span.style.pointerEvents = 'none';
    span.style.width = 0;
    span.style.height = 0;

    span.setAttribute('data-position-aware', true);
    return span;
  }

  #duplicateContent() {
    let content = document.createElement('span');
    content.innerHTML = this.innerHTML;
    content.style.position = 'relative';
    content.style.zIndex = 2;
    content.style.pointerEvents = 'none';
    content.style.display = 'block';
    return content;
  }
}

export default {
  inserted(el, binding) {
    if (binding?.value === false) return;

    el._pa = new PositionAware();
    el._pa.set(el);
  },

  update(el, binding) {
    if (binding?.value === false) return;

    if (!el._pa) {
      el._pa = new PositionAware();
      el._pa.set(el);
    } else {
      el._pa.set(el);
    }
  },
};
