/**
 * @module SaveService
 * @description SaveService handles save button loading state and updates timestamp.
 * @example timestamp examples: https://momentjscom.readthedocs.io/en/latest/moment/04-displaying/02-fromnow/
 */
import moment from 'moment';

import TimeDifferenceFilter from '@master/Filters/_filters/TimeDifferenceFilter';
import { clone } from '@helpers/Global';

class SaveService {
  #resolvers = new Set();

  #saving = false;
  #timestamp = null;
  #unix = null;
  #data;

  constructor() {
    this.#change();
  }

  get data() {
    return this.#data;
  }

  set data(data) {
    this.#data = data;
    for (const cb of this.#resolvers) {
      this.#send(cb);
    }
  }

  subscribe(cb, vnode = null) {
    if (vnode == null && process.env.NODE_ENV === 'development') {
      console.warn('Subscriber added without vnode reference');
    }

    if (vnode != null && typeof vnode.$once === 'function') {
      vnode.$once('hook:beforeDestroy', _ => {
        this.#resolvers.delete(cb);
      });
    }

    this.#resolvers.add(cb);
    this.#send(cb);
  }

  #send(fn) {
    if (this.#data === undefined) return;
    fn(clone(this.#data));
  }

  startSaving() {
    this.#saving = true;
    this.#change();
  }

  stopSaving() {
    this.#saving = false;
    this.#change();
  }

  setTimestamp(unix) {
    unix = unix ?? moment().unix();
    this.#unix = unix;
    this.#timestamp = TimeDifferenceFilter(unix);
    this.#change();
  }

  getTimestamp() {
    return this.#timestamp;
  }

  getUnix() {
    return this.#unix;
  }

  #change() {
    this.data = {
      saving: this.#saving,
      timestamp: this.#timestamp,
    };
  }
}

export default {
  creative: new SaveService(),
  flight: new SaveService(),
};
