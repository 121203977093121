import AssetHelper from '@helpers/Asset';

import TemplateHandler from './TemplateHandler';
import Slot from './Slot';
import Drawable from './Drawable';
import CustomAssetService from '@master/Services/Cache/CustomAssetService';

const DEFAULT_VIDEO_CONTROL_SIZE = 24;

export default class AdditionalHandler {
  #handler;

  /**
   * @param {TemplateHandler} handler
   */
  constructor(handler) {
    this.#handler = handler;

    /**
     * @type {Object.<string, Slot[]>}
     */
    this.slots = {
      Assets: [],
      Overlay: [],
      Hotspot: [],
    };

    // assets that exist but are not drawb by default
    // should be list of assets that are related to another asset
    // eg video controls, hotspot pin, close etc
    this.leftover = [];
  }

  handleAssets() {
    const background = this.#handler.getBackground();
    const active_is_hotspot = background.isActiveHotspot();
    const active_asset_id = background.getActiveAssetId();

    const exclude_hide = [];
    if (AssetHelper.isCustomHotspotMain(active_asset_id)) {
      // by default hotspot assets are hidden
      // make sure to show the active HS close or it will be drawn by default to top corner
      exclude_hide.push(active_asset_id.replace('_main', '_close'));
    }

    this.leftover = this.#getValidAdditionals();

    // handle uploaded additonal assets
    for (const uploaded_asset of this.leftover) {
      // dont handle additionals that are hidden in cropper
      // they are added to other assets via relation later
      if (AssetHelper.isHiddenInCropper(uploaded_asset.asset_id) && !exclude_hide.includes(uploaded_asset.asset_id)) {
        continue;
      }

      const asset_id = uploaded_asset.asset_id;

      // all additionals drawn to overlay layer by default
      let target_array = this.slots.Overlay;

      // additional feed are ones that belong to asset layer
      // except hs close, which should go on overlay
      if (!AssetHelper.isCustomHotspotClose(asset_id) && (active_is_hotspot || AssetHelper.isAdditionalFeed(asset_id))) {
        target_array = this.slots.Assets;
      }

      const slot = new Slot(asset_id, uploaded_asset);

      if (AssetHelper.isAdditionalFeed(asset_id)) {
        slot.adjustForFeed(this.#handler.getFirstFeedAsset());
      } else {
        slot.adjustForOverlay(background.layers.Overlay);
      }

      target_array.push(slot);

      // remove self from the list
      this.leftover = this.leftover.filter(additional => additional.asset_id !== asset_id);
    }

    // since above function ignores hotspots, add hotspot main asset to drawables
    if (active_is_hotspot) {
      this.#handleActiveHotspot();
    }
  }

  /**
   * @param {Drawable[]} drawables
   */
  async assignLeftovers(drawables) {
    const active_asset_id = this.#handler.getBackground().getActiveAssetId();

    for (const drawable of drawables) {
      if (AssetHelper.isCustomHotspotMain(drawable.asset_id)) {
        this.leftover = drawable.claimRelatedHotspotAssets(this.leftover, active_asset_id);
      } else {
        this.leftover = drawable.claimRelatedAssets(this.leftover, active_asset_id);
      }
      await this.#validateDrawableVideoControls(drawable);
    }
  }

  #handleActiveHotspot() {
    const active_asset_id = this.#handler.getBackground().getActiveAssetId();

    // only add main when dealing with close icon
    // as when we modify gesture -> hotspot should be hidden
    if (AssetHelper.isCustomHotspotClose(active_asset_id) || AssetHelper.isCustomHotspotVideoAsset(active_asset_id)) {
      const match = active_asset_id.match(/custom_hs_(.+)_/i);
      if (match) {
        const hs_main_asset_id = `custom_hs_${match[1]}_main`;

        const assets = this.#getAssets();

        if (assets[hs_main_asset_id] != null) {
          const slot = new Slot(hs_main_asset_id, assets[hs_main_asset_id]);

          // add main to assets layer so it would be under the active layer
          const layer = this.#handler.getBackground().layers.Overlay;
          slot.adjustForOverlay(layer);

          this.slots.Hotspot.unshift(slot);

          // remove self from the list
          this.leftover = this.leftover.filter(additional => additional.asset_id !== hs_main_asset_id);
        }
      }
    }
  }

  #getAssets() {
    const creative = this.#handler.getCreative();
    return creative.additional_assets ?? {};
  }

  // filter additionals only to have the ones that have image
  #getValidAdditionals() {
    this.leftover = [];

    const assets = this.#getAssets();
    const valid = [];

    for (const asset_id in assets) {
      const uploaded_asset = assets[asset_id];

      // we only draw 1 state asset, so ignore the state not used when editing
      if (AssetHelper.isCustomVideoPause(asset_id) || AssetHelper.isCustomVideoUnmute(asset_id)) {
        continue;
      }

      // push to first because reversed draw order
      valid.unshift(uploaded_asset);
    }
    return valid;
  }

  /**
   * @param {Drawable} drawable
   */
  async #validateDrawableVideoControls(drawable) {
    const active_asset_id = this.#handler.getBackground().getActiveAssetId();

    const creative = this.#handler.getCreative();
    const asset_settings = creative.settings?.assets ?? {};

    const asset_setting = asset_settings[drawable.asset_id];

    // asset without settings is certainly not a video
    if (!asset_setting) {
      return;
    }

    let video_control = drawable.related.find(related => AssetHelper.isCustomVideoPlay(related.asset_id));
    if (asset_setting.video_controls && !video_control) {
      const key = 'custom_video_play';
      // since no custom, create pseudo asset by the custom key
      if (active_asset_id !== `${key}_${drawable.asset_id}`) {
        const video_control = await this.#createDefaultVideoAsset(key);
        if (video_control) {
          drawable.related.push(video_control);
        }
      }
    } else if (!asset_setting.video_controls && video_control) {
      // setting is off, so remove the asset from stack (as turning setting off does not remove the asset)
      drawable.related = drawable.related.filter(related => !AssetHelper.isCustomVideoPlay(related.asset_id));
    }

    let video_audio = drawable.related.find(related => AssetHelper.isCustomVideoMute(related.asset_id));
    if (asset_setting.audio && !video_audio) {
      const key = 'custom_video_mute';
      // since no custom, create pseudo asset by the custom key
      if (active_asset_id !== `${key}_${drawable.asset_id}`) {
        const video_audio = await this.#createDefaultVideoAsset(key);
        if (video_audio) {
          drawable.related.push(video_audio);
        }
      }
    } else if (!asset_setting.audio && video_audio) {
      // setting is off, so remove the asset from stack (as turning setting off does not remove the asset)
      drawable.related = drawable.related.filter(related => !AssetHelper.isCustomVideoMute(related.asset_id));
    }
  }

  async #createDefaultVideoAsset(key) {
    const collection = CustomAssetService.get(key);

    if (collection.empty()) {
      await collection.load();
    }
    if (collection.empty()) {
      return null;
    }

    // since functions expect the prefix + _id, mock the id
    // right now the asset is directly added where it belongs
    // in future might use correct parent asset ID
    const asset_id = key + '_mock';

    return {
      asset_id,
      blob: collection.first().blob,
      settings: {
        // since handled as overlay, only need SourceLayer
        SourceLayer: {
          size: {
            width: DEFAULT_VIDEO_CONTROL_SIZE,
            height: DEFAULT_VIDEO_CONTROL_SIZE,
          },
          offset: {
            x: key === 'custom_video_mute' ? 0.95 : -0.95,
            y: 0.95,
          },
        },
      },
    };
  }
}
