export default (value, floor = false) => {
  if (value == null) return 'NaN';

  let number = Math.round(value * 10) / 10;
  let suffix = '';

  if (value >= 1000000000) {
    number = Math.round(value / 10000000) / 100;
    suffix = 'B';
  } else if (value >= 1000000) {
    number = Math.round(value / 10000) / 100;
    suffix = 'M';
  } else if (value >= 1000) {
    number = Math.round(value / 10) / 100;
    suffix = 'k';
  }

  if (floor === true && suffix === '') {
    number = Math.floor(number);
  }

  return `${number}${suffix}`;
};
