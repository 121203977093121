import Directives from '@master/Directives';

function replace(char, index) {
  return index === 0 ? char.toLocaleLowerCase() : `-${char.toLowerCase()}`;
}

export default {
  install: Vue => {
    Object.keys(Directives).forEach(key =>
      Vue.directive(key.replace(/[A-Z]/g, replace), Directives[key]),
    );
  },
};
