<template lang="pug">
div.files-list-container
  div.files-list-row.files-list-header
    span(v-for="header of ['File name', 'Orig. size', 'Optimized size', 'Status']" :key="header.text") {{ header }}

  File(v-for="image in images" :key="image.safe_name" :file="image" @update="update")
</template>

<script>
import File from '@components/File.vue';

export default {
  name: 'FilesList',

  components: {
    File,
  },

  props: {
    images: Array,
  },

  methods: {
    update(zip_size, original_total_size, optimized_total_size) {
      this.$emit('updateZip', zip_size, original_total_size, optimized_total_size);
    },
  },
};
</script>
