<template lang="pug">
div.nexd-info-container
  div.nexd-info-wrap
    div.nexd-info-header.subheader.text-center Why use a media optimizer?
    div.row.col-24.mt-24
      div.col-12
        div.nexd-info-label.text-right Lower costs
        div.nexd-info-content.text-right Improve your bottom line by compressing and optimizing image and video files. Smaller files use less bandwidth and save costs.
      div.col-12
        div.nexd-info-label Happier users
        div.nexd-info-content Whether it's in ads, product images, or even background video, smaller files mean faster load time, which makes happy users.
</template>

<script>
export default {
  name: 'NexdInfo',
};
</script>
