const templates = {
  analytics: {},
  cm: {},
  mb: {},
  cropper: {},
  upgrade: {},
};

templates.test = `<div style="width: 200px">
    Lorem Ipsum is simply dummy text of the printing and typesetting industry.
  </div>
  <a href="https://www.google.com" target="_blank">Google</a>`;

// methods to help build up the template
function head(content) {
  return `<div class="tooltip-head">${content}</div>`;
}
function paragraph(content, width = 240) {
  return `<div class="tooltip-body" style="width: ${width}px;">${content}</div>`;
}
function link(href, content = 'Read more') {
  return `<a class="tooltip-link" href="${href}" target="_blank">${content}</a>`;
}
function rooturl() {
  return process.env.NODE_ENV === 'development' ? '/#' : '';
}

let _a = templates.analytics;
// analytics tooltip templates
_a.summaryCard =
  paragraph('Main metrics about your creative. Impressions, Engagement, CTR and Exposure Time are some of the metrics to understand the performance of your creative.') +
  link('https://support.nexd.com/using-adcanvas/analyze/the-adcanvas-analytics-dashboard#creative-statistics');
_a.impressions = paragraph('Amount of served impressions.');
_a.impressions1 = paragraph('Amount of served impressions. You can click the arrow to see more impression data.');
// link('https://support.nexd.com/using-adcanvas/analyze/the-adcanvas-analytics-dashboard#impressions');
_a.viewable = paragraph('Amount of impressions where half of the ad was viewable for more than one second.');
// link('https://support.nexd.com/using-adcanvas/analyze/the-adcanvas-analytics-dashboard#campaign-statistics-summary');
_a.viewability = paragraph('Percentage of impressions where half of the ad was viewable for more than one second.');
_a.loaded = paragraph('Percentage of time duration the ad fully loaded before the user scrolled past or left the ad.');
// link('https://support.nexd.com/using-adcanvas/analyze/the-adcanvas-analytics-dashboard#campaign-statistics-summary');
_a.billable = paragraph('Amount of impressions where the ad was loaded on web pages or viewable in-app.');

_a.loadedImp = paragraph('Amount of impressions where ad fully loaded before the user scrolled past or left the ad.');
_a.ctrPanel = paragraph('CTR is count when user clicked on the ad.');
// link('https://support.nexd.com/using-adcanvas/analyze/the-adcanvas-analytics-dashboard#campaign-statistics-summary');
_a.ctr = paragraph('Total average click-through rate, the percentage of impressions where user clicked on the ad.');
_a.clicks = paragraph('Amount of users that clicked on the ad.');
_a.engagementPanel = paragraph('Engagement combines different user interactions (including video viewing) in the ad.');
_a.engagement =
  paragraph('Combined average of impressions with user interactions (including video viewing of at least 25%) in the ad.') + link('https://support.nexd.com/en/articles/1462814-how-do-we-calculate-engagement-statistics');
_a.engagementImpressions =
  paragraph('Amount of impressions where user engaged (including video viewing of at least 25%) with the creative.') + link('https://support.nexd.com/en/articles/1462814-how-do-we-calculate-engagement-statistics');
_a.exposureTime = paragraph('Combined average time an ad is viewable on a page.');
_a.performanceCard = paragraph('Main metrics about the creative in selected time range.');
_a.engagementCard = paragraph('Analyze the user-made behaviours in your creatives.') + link('https://support.nexd.com/en/articles/1462814-how-do-we-calculate-engagement-statistics');
_a.engagementInteractionCard = paragraph('Analyze behaviours in time spent inside the ad.');
_a.eventsListCard = paragraph('Combined sum of events and their occurrance in selected time range.');
// link('https://support.nexd.com/using-adcanvas/analyze/the-adcanvas-analytics-dashboard#event-analytics');
_a.videoThroughRateCard = paragraph('How many users reached into which quadrile in viewing the video.');
_a.videoEngagementCard = paragraph('Analyze video play time second-by-second.');
_a.videoStartedWatching = paragraph('How many users initiated the video playback.');
_a.videoUniqueImpressions = paragraph('How many unique impressions initiated the video playback.');
_a.videoCompletedWatching = paragraph('How many of the impressions watched the video till the end.');
_a.dateRangeTime = paragraph('Filter your analytics data by selecting the date range. The default range is the period when the campaign was live.');
_a.switch = paragraph('Impressions show the total served impressions in your campaign. Viewable Impressions show the number of impressions where half of the ad was in view for more than one second.');
_a.trackedAds = paragraph('Amount of impressions where ad was initiated in end placement (excludes invalid traffic filtration).');
_a.dollarSign = paragraph('This icon indicates what impressions is used when invoicing.');
_a.categorydivison = paragraph('Percentage of each category in the largest statistics above.');
_a.sendReport = paragraph('Insert emails separated with comma if you like to send report to multiple emails.');
_a.campaignExportInfo =
  /* eslint-disable max-len */
  paragraph(`XLSX report contains a summary sheet of each creative performance totals + event totals.<br><br>
  XLSX with details contains a summary sheet but also a sheet for each creative containing daily performance + events.<br><br>
  XLSX pivot contains multiple pivot tables on separate sheets and combined creative performance + events data sheets.<br><br>
  XLSX details is only available for campaigns that have <strong>less than 50 creatives</strong>.<br>
  XLSX pivot is only available for campaigns that have <strong>less than 200 creatives</strong>.`);
_a.creativeExportInfo =
  /* eslint-disable max-len */
  paragraph('XLSX report contains a summary sheet of creative daily performance + events.');

let _b = templates.cm;
// cm tooltip
_b.creativeType = paragraph('Select ad type for your creative.') + link('https://support.nexd.com/en/articles/3524425-choosing-between-different-creative-types');
_b.platform =
  paragraph('Select a platform where you publish the ad. Then, an output tag will consist of appropriate macros. Notify our support if your DSP or platform is not listed.') +
  link('https://support.nexd.com/en/articles/1462811-supported-channels-platforms');
_b.creativeStatus_0 = paragraph('Creative has not been published yet.');
_b.creativeStatus_1 = paragraph('Creative has been published, but it had less than 500 impressions in the last 24h.');
_b.creativeStatus_2 = paragraph('Creative has been live and it had less than 500 impressions in the last 24h.');
_b.creativeStatus_3 = paragraph('Creative had over 500 impressions in the last 24h.');
_b.creativeStatus_6 = paragraph('Creative has been live, but it had less than 500 impressions in the last 8 days or it has been in "Paused" status for more than 8 days.');
_b.creativeStatus_69 = paragraph('Creative has been live, but it had less than 500 impressions in the last 8 days or it has been in "Paused" status for more than 8 days.');
_b.creativeStatus_8 = paragraph('Creative has been archived.');
_b.creativeStatus_googleads = paragraph('Creative has been published for external platform and further status can be viewed only in that platform.');
_b.campaignStatus_0 = paragraph('No creatives have been published yet.');
_b.campaignStatus_1 = paragraph('At least one creative has been published, but it had less than 500 impressions in the last 24h.');
_b.campaignStatus_2 = paragraph(
  'At least one creative has been published. At least one creative has been in Live status and currently all creatives that have been in Live have had less than 500 impressions in last 24h.',
);
_b.campaignStatus_3 = paragraph('At least one creative had over 500 impressions in the last 24h.');
_b.campaignStatus_6 = paragraph('At least one creative has been live, but it had less than 500 impressions in the last 8 days.');
_b.campaignStatus_8 = paragraph('Campaign has been archived.');
_b.excelImport = paragraph('Learn how to create multiple placements with a template on <a href="https://support.nexd.com/en/articles/3860509-bulk-create-placements" target="_blank">our support page</a>.');
_b.quantumImport = paragraph('You can find the specs for this category <a href="https://support.nexd.com/en/articles/3925615-automate-making-a-responsive-creative" target="_blank">our support page</a>.');
_b.placementSize = paragraph('Placement size is the visible area purchased on the website where the ad will be published.');
_b.placementSizeResponsive =
  _b.placementSize + paragraph('For responsive these are the sizes that the ad is optimized for. The ad works with sizes between the listed ones, but always test beforehand in your publishing platform.');
_b.placementSize = _b.placementSize + link('https://support.nexd.com/en/articles/5162178-creative-placement-sizes', 'Read about infeed sizes');
_b.initialLoadSize = paragraph('The size of files to complete the first display of the ad (usually splash).') + link('https://support.nexd.com/en/articles/4556741-creative-size-and-asset-optimization');
_b.totalLoadSize =
  paragraph('Initial load size plus the size of all asset files, including videos and images that load after interaction.') + link('https://support.nexd.com/en/articles/4556741-creative-size-and-asset-optimization');
_b.countDownLabels =
  paragraph('Select which time units to display on the countdown and what to display as label for each time unit.') + link('https://support.nexd.com/en/articles/4482760-add-a-countdown-on-the-creative');

_b.googleadsSizeWarning =
  /* eslint-disable max-len */
  paragraph(
    'Google Ads platform allows third party creatives under 600 Kb. To be within limits, optimize each asset to achieve the desired quality/size balance. Please note that the published creative ZIP size may differ from the approximate depending on your selected layout and options.',
  ) + link('https://support.nexd.com/en/articles/4563259-publish-for-google-ads');

_b.creativeOptimizer =
  paragraph('Use the optimization level slider to see results of creative optimized load size. Just make sure that the creative looks also nice after that.') +
  link('https://support.nexd.com/en/articles/4556741-creative-load-size-and-optimization');

_b.creativeOptimizerQualityLevels = paragraph(
  'By changing the slider value, the quality and optimization level of the creative changes. 1 equals lowest quality and smaller total size. 10 equals highest quality and bigger total size.',
);

_b.standard_infeed =
  paragraph('Standard infeed ads are the most common ad type that appears inside of content feeds. They are part of the page flow and scroll along just like the rest of the content.') +
  link('https://www.nexd.com/ad-types/#mobile-infeed');

_b.interstitial = paragraph('Interstitial ads are fullscreen ads that overlay the content.') + link('https://www.nexd.com/ad-types/#mobile-interstitial');

_b.interscroller =
  paragraph('Interscroller ads are scroll-in ads, where creatives are fullscreen size, but visible through a viewport. The viewport is the size of the placement, scrolling through publisher content reveals the ad.') +
  link('https://www.nexd.com/ad-types/#mobile-interscroller');

_b.expandable = paragraph('Expandable ads are two-state ads that transition from the first state to the second state.') + link('https://www.nexd.com/ad-types/#mobile-expandable');

_b.endcard = paragraph('Dynamic endcard ads are two-state ads that start with a video and then show the second state with any mobile layout.');

_b.lightbox = paragraph('Lightbox ads are two-state ads that open the second state with a popup.') + link('https://www.nexd.com/ad-types/#mobile-expandable');

_b.responsive =
  paragraph('Responsive is a creative that can be used in different infeed placements. It takes one set of assets and automatically creates multiple ads in different desktop and mobile sizes.') +
  link('https://www.nexd.com/ad-types/#responsive');

_b.vast = paragraph('VAST Video is VAST standard-based ads for video-sharing platform in-stream placements where you can use our video layout.') + link('https://www.nexd.com/ad-types/#instream-video-ad');

_b.ctv_vast =
  paragraph(
    'CTV VAST video is the upgraded VAST type for video-sharing platforms that run in wide screens such as TV. The default resolution is UHD(4K) making the CTV VAST type the right choice for the ad to be delivered in high quality.',
  ) + link('https://www.nexd.com/ad-types/#instream-video-ad');

_b.vpaid = paragraph('VPAID Video is VPAID (SIMID) standard-based ads for video-sharing platform in-stream placements where you can use all our layouts.') + link('https://www.nexd.com/ad-types/#instream-video-ad');

_b.gyroWarning =
  paragraph('Apple has disabled gyroscope access for Safari by default. In this case, creatives have swipe interaction. All ads work with gyro in Android, other browsers and apps.') +
  link('https://support.nexd.com/en/articles/2850104-ios-12-2-release-has-brought-confusion-for-gyroscope-content');

_b.exportVideo = paragraph('Choose if you want to get a video file of the creative that can be used in other platforms (eg social media).') + link('https://support.nexd.com/en/articles/5587695-export-as-video');

_b.exportVideoDisabled =
  paragraph('To get a video file of the creative that can be used in other platforms (eg social media), the layout needs to be auto animated. You can find auto animated layouts using filters in the layout gallery.') +
  link('https://support.nexd.com/en/articles/5587695-export-as-video');

_b.trackingGlobal =
  paragraph('Add your preferred tracker which will be notified when the event is triggered.') + link('https://support.nexd.com/en/articles/3674591-using-pixel-trackers', 'Read more about tracker events');

_b.trackingAssetSpecific =
  paragraph('Add your preferred tracker which will be notified when the specific asset’s event is triggered.') +
  link('https://support.nexd.com/en/articles/3674591-using-pixel-trackers', 'Read more about tracker events');

_b.trackingQuestion =
  paragraph('Add your preferred tracker which will be notified when the answer is shown. You can also use the following variables in the URLs: {questionOrder} and {questionText}.') +
  link('https://support.nexd.com/en/articles/5534359-carry-out-surveys-and-engage-ad-viewers-with-quizzes#h_f25df34bce', 'Read more');

_b.choiceQuestion =
  paragraph('Add your preferred tracker which will be notified when the choice has been selected. You can also use the following variables in the URLs: {questionOrder}, {questionText}, {choiceOrder} and {choiceText}.') +
  link('https://support.nexd.com/en/articles/5534359-carry-out-surveys-and-engage-ad-viewers-with-quizzes#h_f25df34bce', 'Read more');

_b.ctaGlobal = paragraph('Choose what happens when the ad viewer clicks on the ad.') + link('https://support.nexd.com/en/articles/1462801-add-a-cta-to-creatives', 'Read more about different CTA types');

_b.ctaAssetSpecific =
  paragraph('Optional. If not filled, Global action will be triggered. Select the action that you want to do when the user clicks on the specific asset.') +
  link('https://support.nexd.com/en/articles/1462801-add-a-cta-to-creatives', 'Read more about different CTA types');

_b.inappSupport = paragraph(
  'Toggle if you are trafficking the creative in mobile applications inventory. If you do not know, you do not need to enable it. Please also enable MRAID when trafficking the creative in your platform.',
);

_b.v2packbuilder = paragraph(
  'With the new quick ad pack builder changes applied are seen faster in the preview. When having issues with the creative working properly, try turning off this setting to use legacy ad pack builder.',
);

_b.personallyIdentifiableInformation =
  paragraph('Please add third-party trackers under the Tracking tab as Nexd does not collect personal data and you will lose the information.') +
  link('https://support.nexd.com/en/articles/5534359-carry-out-surveys-and-engage-ad-viewers-with-quizzes#h_f25df34bce', 'Read more');

_b.personallyIdentifiableInformationTracking =
  paragraph('Please provide your own tracker and use the relevant macros as Nexd does not collect personal data and you will lose the information.') +
  link('https://support.nexd.com/en/articles/5534359-carry-out-surveys-and-engage-ad-viewers-with-quizzes#h_f25df34bce', 'Read more');

_b.moatTracking = paragraph('This creative is being tracked also by MOAT Ad Verification system. Access to MOAT report.') + link('https://support.nexd.com/en/articles/6299999-release-notes-2022-06-08', 'Read more');

_b.moatTrackingGoogleads = paragraph('Third-party tracking is not allowed in Google Ads. Please use another platform to buy inventory with third-party tracking.');

_b.moatTrackingLive = paragraph('This creative has already been live and therefore cannot be included in MOAT tracking. Duplicate the creative and republish to enable MOAT tracking.');

_b.moatTrackingDenied = paragraph('Your account does not have access to MOAT tracking. Please reach out to your Account Manager or to Support directly via support@nexd.com');

_b.updateLiveButton = paragraph('Re-export after small changes') + link('https://support.nexd.com/en/articles/1462809-export-creatives#:~:text=Update%20live%20creative', 'Read more about updating creatives');

let _c = templates.cropper;
_c.legend = `<div class="cropper-tooltip-body">
  ${head('Scale and Crop Legend')}
  ${link('https://support.nexd.com/en/articles/3998445-scale-and-crop-assets', 'Get more help from our support article')}
  <div class="cropper-tooltip-row">
    <div class="placement-area"></div><small>Placement area</small>
  </div>
  <div class="cropper-tooltip-row">
    <div class="asset-area"></div><small>Asset area</small>
  </div>
  <div class="cropper-tooltip-row">
    <div class="empty-placement-area"></div><small>Empty placement area</small>
  </div>
  <div class="cropper-tooltip-row">
    <div class="empty-asset-area"></div><small>Empty asset area</small>
  </div>
  <div class="cropper-tooltip-row">
    <div class="inactive-asset"></div><small>Inactive asset</small>
  </div>
  <div class="cropper-tooltip-row">
    <div class="cropped-area"></div><small>Cropped area</small>
  </div>
</div>`;
_c.module = `<div class="cropper-tooltip-body">
  ${head('Module Position Legend')}
  ${link('https://support.nexd.com/en/articles/4442282-change-responsive-creative-different-placement-sizes', 'Get more help from our support article')}
  <div class="cropper-tooltip-row">
    <div class="asset-area"></div><small>Placement area</small>
  </div>
  <div class="cropper-tooltip-row">
    <div class="placement-area"></div><small>Module area</small>
  </div>
  <div class="cropper-tooltip-row">
    <div class="cropped-area"></div><small>Hidden module area</small>
  </div>
</div>`;
_c.safe_area_toggle = paragraph('Choose whether to show safe areas.') + link('https://support.nexd.com/en/articles/3451578-selecting-high-or-low-aspect-ratio-mobile-fullscreen-size');
let _d = templates.mb;
_d.disabled_publish =
  head('In order to publish...') + paragraph('Please select campaign goal, start and end dates, total and ad set budgets and the creative(s).') + link('https://support.nexd.com/en/articles/4850141-buy-media');
_d.disabled_publish_no_card = _d.disabled_publish + paragraph(`You also need to <a href="${rooturl()}/billing" target="_blank">add a credit card</a>.`);
_d.card_detail_tooltip = paragraph(`This is the credit card on file with Nexd. You can also <a href="${rooturl()}/billing" target="_blank">add a new credit card</a>.`);
_d.disabled_republish = head('In order to republish...') + paragraph('You need to change the campaign or any ad set settings.') + link('https://support.nexd.com/en/articles/4850141-buy-media');
_d.disabled_republish_no_card = _d.disabled_republish + paragraph(`You also need to <a href="${rooturl()}/billing" target="_blank">add a credit card</a>.`);
_d.disabled_republish_finished_campaign = head('In order to republish...') + paragraph('campaign end date needs to be today or in the future.') + link('https://support.nexd.com/en/articles/4850141-buy-media');
_d.disabled_republish_finished_campaign_no_card = _d.disabled_republish_finished_campaign + paragraph(`You also need to <a href="${rooturl()}/billing" target="_blank">add a credit card</a>.`);
_d.goal_global =
  paragraph("Select what makes this campaign successful for you. Whether it is getting ad viewers ('Awareness') or website clicks ('Sales')") +
  link('https://support.nexd.com/en/articles/4850141-buy-media-and-publish#h_4283e659bb', 'Read more');
_d.goal_awareness = paragraph('We optimize your campaign to get most viewers to show your ad.') + link('https://support.nexd.com/en/articles/4850141-buy-media#h_1e2bb74ed0', 'Read about goal settings');
_d.goal_sales =
  paragraph('We optimize your campaign to get most clicks on the ad to drive people to your website.') + link('https://support.nexd.com/en/articles/4850141-buy-media#h_1e2bb74ed0', 'Read about goal settings');
_d.forecasting =
  paragraph('When campaign start and end dates are set then we’ll show you forecasted impressions or clicks. When making changes, save and the numbers will be updated.') +
  link('https://support.nexd.com/en/articles/4850141-buy-media#h_2c4f91bb0b', 'Read more about forecasting');
_d.audienceBlock =
  paragraph("Depending on your goal, budget, and the product or service you're advertising, you may want to set a wide or a narrow audience.") +
  link('https://support.nexd.com/en/articles/4850141-buy-media#h_86175b52f5');
_d.gender =
  paragraph('Please note that you can only use gender in US, UK, Germany, Italy, France and Spain.') + link('https://support.nexd.com/en/articles/4850141-buy-media#h_4957705d74', 'Learn more about gender targeting');
_d.agegroup =
  paragraph('Please note that you can only use age group in US, UK, Germany, Italy, France and Spain.') +
  link('https://support.nexd.com/en/articles/4850141-buy-media#h_4957705d74', 'Learn more about age group targeting');
_d.total_budget =
  paragraph('We will never charge above this number. This acts as a limit, so if your total budget is 1000€ and the sum of all Ad set budgets is 1500€, you will not spend more than 1000€.') +
  link('https://support.nexd.com/en/articles/4850141-buy-media#h_1b4cb84dc7');
_d.advanced_budget_bidding =
  paragraph('Whether you pay for showing the ad or getting clicked on the ad') + link('https://support.nexd.com/en/articles/5681679-buy-media-and-publish-as-an-expert#h_6cbafeb849', 'Learn more');
_d.advanced_budget_frequency_capping =
  paragraph('You can set the maximum number of viewable impressions per campaign or for each day or hour.') +
  link('https://support.nexd.com/en/articles/5681679-buy-media-and-publish-as-an-expert#h_9b9f2099e4', 'Learn more');

function upgrade(content) {
  return `<div class="content">
    <div class="text">${content}</div>
    <a class="tooltip_link" href="${rooturl()}/billing" class="text-underline">Upgrade</a>
  </div>`;
}

let _fp = templates.upgrade;
_fp.analytics = upgrade('Upgrade to see analytics');

export default templates;
