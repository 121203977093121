import FilterService from '@master/Services/FilterService';
import Subscriber from '@libs/Subscriber';

class AdvertisersService extends Subscriber {
  subscribe(cb, vnode = null) {
    if (this.data === undefined) {
      this.load();
    }
    super.subscribe(cb, vnode);
  }

  load() {
    return super.load('campaigns/advertisers');
  }

  addAdvertiser(new_advertiser) {
    if (this.data === undefined) return;

    const advertisers = this.data;
    const advertiser = this.#get(new_advertiser);

    if (advertiser) {
      advertiser.count++;
    } else {
      advertisers.push({
        value: new_advertiser,
        count: 1,
      });
    }

    this.data = advertisers;
    FilterService.addOption('campaigns.advertisers', new_advertiser);
  }

  removeAdvertiser(removed_advertiser) {
    if (this.data === undefined) return;

    const advertisers = this.data;
    const advertiser = this.#get(removed_advertiser);

    if (advertiser?.count > 1) {
      advertiser.count--;
    } else {
      this.data.splice(this.data.indexOf(advertiser), 1);
    }

    this.data = advertisers;
    FilterService.removeOption('campaigns.advertisers', removed_advertiser);
  }

  #get(advertiser) {
    return this.data?.find(adv => adv.value === advertiser);
  }
}

export default new AdvertisersService();
