/**
 * Intercom service to handle intercom booting for auth and non auth paths
 * Init can be called anywhere, since the service tracks itself which kind of setup is active, and will switch depending on the input
 * Each time auth setup changes, will shutdown intercom as well, but only if auth is defined so intercom has no problems or errors while removing something not created yet
 */

let __disabled = false;

let IntercomService = {
  _app_id: 'zxsca8g8',
  _authorized: null,
  tour_active: false,
  hide_default_launcher: false,

  // if library was loaded in whne init was called
  booted: false,

  // save last init state, so if library will be added later, we should call init with last input
  init_user_state: null,

  init(user = null) {
    this.init_user_state = user;

    if (__disabled) {
      return;
    }

    if (window.Intercom != null) {
      this.booted = true;
      if (user == null) {
        this.startNonAuth();
      } else {
        this.startAuth(user);
      }
    }
  },

  disable() {
    __disabled = true;
  },

  revalidate() {
    // called after library was loaded in
    if (this.booted) return;

    if (window.Intercom != null) {
      this.init(this.init_user_state);

      // make sure to set correct launcehr state
      if (this.hide_default_launcher) {
        this.hideLauncher();
      } else {
        this.showLauncher();
      }
    }
  },

  startNonAuth() {
    // only init if non authorized or no boot is active
    if (this._authorized === true || this._authorized == null) {
      if (this._authorized != null) {
        this.shutdown();
      }
      this._authorized = false;
      const intercom_settings = {
        app_id: this._app_id,
        hide_default_launcher: this.hide_default_launcher,
      };

      if (window.Intercom != null) {
        window.Intercom('boot', intercom_settings);
      }
    }
  },

  startAuth(user) {
    let update = false;

    if (this._authorized === false || this._authorized == null) {
      if (this._authorized != null) {
        this.shutdown();
      }
      this._authorized = true;
    } else {
      // if already authorized boot is made, update with new settings
      update = true;
    }

    let intercom_settings = {
      app_id: this._app_id,
      name: user.name,
      email: user.email,
      phone: user.phone,
      user_id: user.user_id,
      user_hash: user.intercom_hmac,
      signed_up_at: user.created_on,
      custom_attributes: {
        type: user.user_type,
        user_type: user.user_type,
        job_title: user.job_title,
      },
      alignment: 'right',
      horizontal_padding: 10,
      vertical_padding: 10,
      hide_default_launcher: this.hide_default_launcher,
    };

    // Add company id so company web sessions are counted correctly
    if (user?.group) {
      intercom_settings.company = {
        id: user.group.group_id,
        name: user.group.name,
      };
    }

    // init intercom user image
    if (user.img) {
      intercom_settings.avatar = {
        type: 'avatar',
        image_url: user.img,
      };
    }

    if (window.Intercom != null) {
      if (update) {
        window.Intercom('update', intercom_settings);
      } else {
        window.Intercom('boot', intercom_settings);
      }
    }
  },

  shutdown() {
    if (window.Intercom != null) {
      window.Intercom('shutdown');
    }
  },

  routeChange(to) {
    if (window.Intercom != null) {
      if (to.query.product_tour_id != null && this.tour_active !== true) {
        this.startTour(to.query.product_tour_id);
      }
      if (this._authorized === true) {
        window.Intercom('update', {
          last_request_at: parseInt(new Date().getTime() / 1000),
        });
      }
    }
  },

  startTour(id) {
    if (window.Intercom != null) {
      window.Intercom('startTour', id);
    }
  },

  showMessage(msg) {
    if (window.Intercom != null) {
      window.Intercom('showNewMessage', msg);
    }
  },

  showLauncher() {
    this.hide_default_launcher = false;
    if (window.Intercom != null) {
      window.Intercom('update', {
        hide_default_launcher: this.hide_default_launcher,
      });
    }
  },

  hideLauncher() {
    this.hide_default_launcher = true;
    if (window.Intercom != null) {
      window.Intercom('update', {
        hide_default_launcher: this.hide_default_launcher,
      });
    }
  },

  sendEvent(name, metadata) {
    if (window.Intercom != null) {
      window.Intercom('trackEvent', name, metadata);
    }
  },
};

export default IntercomService;
