export function stringHasValue(value) {
  if (typeof value === 'string') {
    value = value.trim();
  }

  return /\b\w+\b/i.test(value);
}

export function isValidPhoneNumber(value) {
  if (typeof value === 'string') {
    value = value.trim();
  }

  return /\+?\d{1,4}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/g.test(value);
}

export function isValidUSSDCode(value) {
  if (typeof value === 'string') {
    value = value.trim();
  }

  return /^\*[0-9\*#]*[0-9]+[0-9\*#]*#$/.test(value);
}

export function isValidPassword(value) {
  return stringHasValue(value) && value?.length >= 8 && value?.length <= 64;
}

export function isValidEmail(value) {
  return stringHasValue(value) && /\S+@\S+\.\S+/.test(value);
}

export function isValidUrl(value) {
  try {
    const url = new URL(value);
    return url.protocol === 'https:';
  } catch (e) {
    return false;
  }
}
