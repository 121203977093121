import SortableTable from '@master/Classes/SortableTable';

export default {
  inserted: (el, binding) => {
    el._SortableTable = new SortableTable(el, binding.value);
  },
  update(el, binding) {
    if (el != null && el._SortableTable != null) {
      el._SortableTable.updateData(binding.value);
    }
  },
};
